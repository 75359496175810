<template>
  <v-dialog v-model="dialog" max-width="800" hide-overlay
            transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>Authorize Online Exam</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field dense outlined label="Supervisor full name *" :error="$v.supervisor.full_name.$error" v-model="supervisor.full_name"></v-text-field>
              <span class="text-danger" v-if="$v.supervisor.full_name.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="6" v-if="type=='phone'">
              <v-text-field dense outlined label="Supervisor mobile *" :error="$v.supervisor.phone.$error" v-model="supervisor.phone">
              </v-text-field>
              <span class="text-danger" v-if="$v.supervisor.phone.$error">This information is required</span>

            </v-col>
            <v-col cols="12" md="6" v-if="type=='email'">
              <v-text-field dense outlined label="Supervisor email *" v-model="supervisor.email" :error="$v.supervisor.email.$error || !$v.supervisor.email.email"></v-text-field>
              <span class="text-danger" v-if="$v.supervisor.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.supervisor.email.email">This information is not valid</span>
            </v-col>
            <v-col cols="12" md="12">
              <p class="subtitle-1">
                <strong>Select date when exam will be taken</strong>
              </p>
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      dense
                      v-model="supervisor.online_exam_supervision_time_limit"
                      @input="formatDate"
                      label="Exam date"
                      prepend-inner-icon="mdi-calendar"
                      prepend-icon=""
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="supervisor.online_exam_supervision_time_limit"
                    @input="menu = false"
                    :min="todayDate"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.supervisor.online_exam_supervision_time_limit.$error">This information is required <br></span>
              <p class="font-weight-regular  subtitle-1">Please note the exam will be authorised till midnight of selected date i.e: 11:59 PM</p>

            </v-col>
            <v-col cols="12" md="12">
              <template >
                  <p class="font-weight-medium subtitle-1">
                    {{message}}
                    <template>
                    <a @click="changeSupervisorContact"  href="#">
                      Click Here
                    </a>
                  </template>
                  </p>
              </template>
            </v-col>

            <v-col cols="12" v-if="is_class_exam">
              <p class="font-weight-regular  subtitle-1"><strong>Note</strong>: This candidate has enrolled as a class exam and authorising this candidate will also authorise all candidates having same class identifier.</p>

            </v-col>
            <v-col cols="6" v-for="exam in class_exams">
              <v-list-item two-line >
                  <v-list-item-content>
                    <v-list-item-title>{{exam.full_name}}</v-list-item-title>
                    <v-list-item-subtitle>
                      <p class="subtitle-2 font-weight-medium">Enrolment key : {{exam.exam_key}}</p>
                      <p class="subtitle-2 font-weight-medium" v-html="'Enrolment status : '+exam.enrolment_status_badge"> </p>
                      <p class="subtitle-2 font-weight-medium" v-html="'Exam status : '+exam.exam_status_badge"> </p>
                    </v-list-item-subtitle>

                  </v-list-item-content>
                </v-list-item>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn dark rounded x-large
            medium @click="authorizeClassExam()"
            :loading="loading">
          Authorise
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Datetime } from 'vue-datetime';
import CandidateService from "@/services/candidate/CandidateService";
import CandidateOnlineExamSupervisorService from "@/services/candidate/online-exam-supervisor/CandidateOnlineExamSupervisorService";
import {required,email,numeric,requiredIf} from "vuelidate/lib/validators";

const onlineExamSupervisor=new CandidateOnlineExamSupervisorService();
const candidate=new CandidateService();
export default {
  components:{
    Datetime
  },
  validations: {
    supervisor: {
      full_name: {required},
      online_exam_supervision_time_limit:{required},
      email: {
        required: requiredIf(function (nestedModel) {
          return this.type == 'email';
        }), email},
      phone: {
        required: requiredIf(function (nestedModel) {
          return this.type == 'phone';
        }),
      },
    }
  },
  data(){
    return{
      menu:false,
      dialog:false,
      loading:false,
      supervisor:{
        full_name:'',
        email:'',
        phone:'',
        candidate_ids:'',
        online_exam_supervision_time_limit:'',
      },
      message:'',
      type:'',
      candidateIds:[],
      candidateDetail:{},
      is_class_exam:false,
      class_exams:[],
      todayDate:''
    }
  },
  methods:{
    resetData(){
      this.candidateDetail={};
      this.is_class_exam=false;
      this.supervisor={};
      this.candidateIds=[];
    },
    hasOneDigit(val){
      return String(Math.abs(val)).charAt(0) == val;
    },
    currentDateTime() {
      const current = new Date();

      let current_month=(current.getMonth()+1);
      let current_date=current.getDate();
      let current_minute=current.getMinutes();
      let current_hours=current.getHours();

      if(this.hasOneDigit(current.getMonth())){
        current_month='0'+(current.getMonth()+1)
      }
      if(this.hasOneDigit(current.getDate())){
        current_date='0'+current.getDate();
      }
      if(this.hasOneDigit(current.getMinutes())){
        current_minute='0'+current.getMinutes();
      }
      if(this.hasOneDigit(current.getHours())){
        current_hours='0'+current.getHours();
      }
      const date = current.getFullYear()+'-'+ current_month +'-'+current_date;
     // const time = current_hours + ":" + current_minute + ":" + "00";
      //const dateTime = date +' '+ time;
      return date;

    },
    openDialog(candidateId){
      this.findCandidateDetail(candidateId);
      this.dialog=true;
      this.type='phone';
      this.todayDate = this.currentDateTime();
      this.message='Don\'t have a mobile phone handy ? You can also authorise by email.';
      this.$v.$reset();
      //this.supervisor.online_exam_supervision_time_limit=this.currentDateTime();
    },
    closeDialog(){
      this.dialog=false;
    },
    changeSupervisorContact(){
      if(this.type=='email'){
        this.type='phone';
        this.message='Don\'t have a mobile phone handy ? You can also authorise by email.';
      }else{
        this.type='email';
        this.message='Don\'t have a email address? You can also authorise by mobile.';
      }
    },
    authorizeClassExam(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (this.candidateIds.length > 0) {
          this.loading=true;
          this.supervisor.candidate_ids = this.candidateIds.join(',');
          onlineExamSupervisor
              .create(this.supervisor)
              .then(response => {
                this.$snotify.success("Exam supervised");
                this.closeDialog();
                this.resetData();
                this.$emit('refresh');
              })
              .catch((err) => {

              })
              .finally(() => {
                this.loading=false;
              })
        }
      }
    },
    findCandidateDetail(candidateId){

      candidate
          .show(candidateId)
          .then(response =>{
              this.candidateDetail=response.data.candidate;
              if(this.candidateDetail && this.candidateDetail.is_class_exam){
                this.is_class_exam=this.candidateDetail.is_class_exam;
                this.getAllClassExam();
                this.getAllActiveClassExam();
              }else{
                this.candidateIds.push(candidateId);
              }
          })
          .catch((err) => {

          })
          .finally( () => {
            this.loading = false;
          })
    },
    getAllClassExam(){
      candidate
          .getAllClassExam({'class_exam_identifier':this.candidateDetail.class_exam_identifier,'filter_column':true})
          .then(response =>{
            this.class_exams=response.data.class_exam_candidates;

          })
          .catch((err) => {

          })
          .finally( () => {

          })
    },
    getAllActiveClassExam(){
      candidate
          .getAllClassExam({'class_exam_identifier':this.candidateDetail.class_exam_identifier,'status':'active','filter_column':true})
          .then(response =>{
            let candidates=response.data.class_exam_candidates;
            let candidate_ids=[];
            candidates.forEach(candidate => {
              candidate_ids.push(candidate.id);
            });
            this.candidateIds=candidate_ids;

          })
          .catch((err) => {

          })
          .finally( () => {

          })
    },

    formatDate () {

      let date = this.supervisor.online_exam_supervision_time_limit;
      if (!date) return null

      const [year, month, day] = date.split('-')
      this.supervisor.online_exam_supervision_time_limit = `${month}/${day}/${year}`
    },



  }
}
</script>
<style>

</style>