import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AmebAwardSupervisorService{
    #api = null;
    
    constructor() {
        this.#api = API_URL + 'admin/ameb-award-supervisor';
    }

    getByCandidate(candidateId,index = null, data = {}) {
        let url = `${this.#api}/${candidateId}/get-by-candidate`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    paginate(data={},index = null) {
        let url = `${this.#api}/get`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }

    approve(id) {
        let url = `${this.#api}/${id}/authorise`
        return apiService.put(url)
    }

    decline(id) {
        let url = `${this.#api}/${id}/decline`
        return apiService.put(url)
    }

    delete(gradeId) {
        let url = `${this.#api}/${gradeId}`
        return apiService.delete(url)
    }

    resendNotificatioMail(supervisorId){
        let url = `${this.#api}/${supervisorId}/resend-notification`;
        return apiService.post(url);
    }
}
