<template>
  <v-app>
    <v-dialog v-model="dialog" width="900">
      <v-card>

        <v-toolbar flat>
          <v-card-title>
            <span>Transfer this candidate to another enroller</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="close">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
        <v-row >
          <v-col cols="6">
            <h4 class="ml-4">Current Enroller</h4>
            <v-card class="ml-2" style="min-height: 250px">

              <v-card-text>
                <div class="d-flex mb-3 justify-content-between">
                  <div class="font-size-lg font-weight-bold">
                    Name
                    </div>
                    <div class="font-size-lg">
                        {{accountHolderDetail.full_name}}
                    </div>
                  </div>
                <div class="d-flex mb-3 justify-content-between">
                  <div class="font-size-lg font-weight-bold">
                    Email
                    </div>
                    <div class="font-size-lg">
                        {{accountHolderDetail.email}}
                    </div>
                  </div>
                <div class="d-flex mb-3 justify-content-between">
                  <div class="font-size-lg font-weight-bold">
                    Phone
                    </div>
                    <div class="font-size-lg">
                        {{accountHolderDetail.phone ? accountHolderDetail.phone : 'NA' }}
                    </div>
                  </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <h4 class="ml-4">New Enroller</h4>
            <v-card class="mr-2" style="min-height: 250px">
              <v-card-text>
                <div class="d-flex mb-3 justify-content-between" v-if="newAccountHolderDetail.first_name">
                  <div class="font-size-lg font-weight-bold">
                    Name
                    </div>
                    <div class="font-size-lg">
                        {{newAccountHolderDetail.first_name}} {{newAccountHolderDetail.last_name}}
                    </div>
                  </div>
                <div class="d-flex mb-3 justify-content-between" v-if="newAccountHolderDetail.email">
                  <div class="font-size-lg font-weight-bold">
                    Email
                    </div>
                    <div class="font-size-lg">
                        {{newAccountHolderDetail.email}}
                    </div>
                  </div>
                <div class="d-flex mb-3 justify-content-between" v-if="newAccountHolderDetail.phone">
                  <div class="font-size-lg font-weight-bold">
                    Phone
                    </div>
                    <div class="font-size-lg">
                        {{newAccountHolderDetail.phone ? newAccountHolderDetail.phone : 'NA' }}
                    </div>
                  </div>
                <div class="mb-3 mt-3 justify-content-between">
                  <div class="font-size-lg">
                        <v-text-field dense outlined label="Search enroller email" :error="$v.newAccountHolderDetail.id.$error" v-model="new_accountHolder.email">
                        </v-text-field>
                    <span class="text-danger" v-if="emailMessage">{{emailMessage}}</span>
                    <span class="text-danger" v-if="$v.newAccountHolderDetail.id.$error">This information is required</span>
                    </div>
                    <v-col class="text-right">
                      <v-btn class="ml-2 mt-2 cancel-btn" text @click="resetAccountholder()" depressed>Reset</v-btn>

                      <v-btn class="mt-2 ml-2"  color="btn btn-primary" dark medium @click="searchByAccountHolderEmail" depressed>Search</v-btn>

                    </v-col>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
              <label>Internal notes </label>
              <span class="text-danger">*</span>
              <br>
              <span>These comments are for internal purposes only and will not be shared with the Enrollers. Comments are saved in the Candidate’s details.</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="remarks" >
              </ckeditor>

            <span class="text-danger" v-if="$v.remarks.$error">This information is required</span>
          </v-col>

          <v-col>
            <label>Type the following confirmation message, inclusive of any spaces, capitals, special characters etc.</label><br>
            <b class="m-3">Hack Me If You Can</b>
            <v-text-field  dense outlined v-model="security.text" :error="$v.security.text.$error"></v-text-field>
            <span class="text-danger" v-if="$v.security.text.$error">This information is required and must be same as instructed</span>

          </v-col>

        </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              x-large
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              medium
              @click="updateCandidateAccountHolder()"
          >
            Confirm transfer
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
  </v-app>
</template>
<script>
import CandidateService from "@/services/candidate/CandidateService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import {required,sameAs} from "vuelidate/lib/validators";
const accountholder=new AccountholderService();
const candidate = new CandidateService();
export default {
  validations: {
    remarks: {required},
    newAccountHolderDetail: {
      id:{required},
    },
    security: {
      text:{required, sameAsConfirm: sameAs('confirm_text')}
    },
  },
  data(){
    return{
      dialog:false,
      candidate_summary:{},
      accountHolderDetail:{},
      new_accountHolder:{
        email:'',
      },
      security:{
        text:'',
        confirm_text:'Hack Me If You Can',
      },
      remarks:'',

      newAccountHolderDetail:{},
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      emailStatus:'',
      emailMessage:'',
    }
  },

  methods:{
    openTransferAccountHolder()
    {
      this.dialog=true;
    },
    getCandidateSummary(){
      candidate
          .getCandidateSummary(this.examKey)
          .then((response) => {
              this.candidate_summary = response.data.candidate;
              this.getAccountHolderDetail();
          })
          .catch((err) => {
          })
          .finally( () => {
          })
    },
    getAccountHolderDetail() {
      accountholder
          .show(this.candidate_summary.owner_id)
          .then(response => {
            this.accountHolderDetail=response.data.accountHolder;
          })
          .catch((err) => {

          });
    },
    searchByAccountHolderEmail()
    {
      accountholder
        .searchByEmail(this.new_accountHolder.email)
            .then(response => {
              if(response.data.status == 'ERROR'){
                this.emailMessage=response.data.message;
                this.newAccountHolderDetail={};

              }
              if(response.data.status=='SUCCESS'){
                this.newAccountHolderDetail=response.data.accountHolder;
                this.emailMessage=null;
              }
              setTimeout(() => {
                this.emailMessage=null;
              }, 5000);
            })
            .catch((err) => {

            });
    },
    resetAccountholder()
    {
      this.new_accountHolder.email='';
      this.newAccountHolderDetail='';

    },
    resetTransferAccountHolder()
    {
      this.new_accountHolder={
        email:'',
      };
      this.security={
        text:''
      };
      this.newAccountHolderDetail={};
      this.remarks='';
      this.$v.$reset()
    },
    close()
    {
      this.dialog=false;
      this.resetTransferAccountHolder();
    },
    updateCandidateAccountHolder()
    {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (this.security.text == 'Hack Me If You Can') {
          let data = {'owner_id': this.newAccountHolderDetail.id, 'remarks': this.remarks}
          candidate
              .transferAccountHolder(this.candidate_summary.id, data)
              .then(response => {
                this.$snotify.success("Transferred successfully");
                this.close();
              })
              .catch((err) => {

              });
        }
      }
    }

  },
  mounted() {
    this.getCandidateSummary();

  },
  computed:{
    examKey(){
      return this.$route.params.examKey;
    }
  },
}
</script>