<template>
  <v-dialog v-model="dialog" scrollable max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Supervisor</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="amebAwardSupervisor.first_name" :error="$v.amebAwardSupervisor.first_name.$error"
                outlined dense>
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.amebAwardSupervisor.first_name.$error">This information is
                required</span>
              <span class="text-danger" v-if="errors.first_name">* {{ errors.first_name[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="amebAwardSupervisor.middle_name" :error="$v.amebAwardSupervisor.middle_name.$error"
                outlined dense>
                <template v-slot:label>
                  Middle name
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.amebAwardSupervisor.middle_name.$error">This information is
                required</span>
              <span class="text-danger" v-if="errors.middle_name">* {{ errors.middle_name[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="amebAwardSupervisor.last_name" :error="$v.amebAwardSupervisor.last_name.$error"
                outlined dense>
                <template v-slot:label>
                  Surname <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.amebAwardSupervisor.last_name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.last_name">* {{ errors.last_name[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="amebAwardSupervisor.email" :error="$v.amebAwardSupervisor.email.$error" outlined
                dense>
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.amebAwardSupervisor.email.$error">Please enter a valid email
              </span>
              <!--                                <span class="text-danger" v-if="errors.email" >{{ errors.email[0] }}</span>-->
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="amebAwardSupervisor.phone" outlined dense>
                <template v-slot:label>
                  Phone
                </template>
              </v-text-field>
              <!--                                <span class="text-danger" v-if="$v.amebAwardSupervisor.phone.$error">This information is required</span>-->
              <!--                                <span class="text-danger" v-if="errors.phone" > {{ errors.phone[0] }}</span>-->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark x-large @click="createOrUpdate()" :loading="loading">
          Save
        </v-btn>
        <v-btn x-large text @click="closeDialog">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import AmebAwardSupervisorService from "@/services/candidate/ameb-award/ameb-award-supervisor/AmebAwardSupervisorService";
import { required, email, requiredIf } from "vuelidate/lib/validators";

const amebAwardSupervisor = new AmebAwardSupervisorService();

export default {
  validations: {
    amebAwardSupervisor: {
      first_name: { required },
      middle_name: {},
      last_name: { required },
      email: { required, email },
      // phone: {required},
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      title: '',
      amebAwardSupervisor: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        notes: '',
        candidate_id: null
      },
      errors: [],
      edit: false,
      candidate_id: null,
      supervisorId: null
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog(candidateId, supervisorId = null) {
      this.$v.$reset();
      this.resetForm();

      this.amebAwardSupervisor.candidate_id = candidateId;

      if (supervisorId) {
        this.edit = true;
        this.supervisorId = supervisorId;
        this.showAmebAwardSupervisor();
        this.title = "Edit";
        this.dialog = true;
      } else {
        this.dialog = true;
        this.edit = false;
        this.title = "Add";
      }
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },

    _create: function () {
      this.loading = true;
      amebAwardSupervisor
        .create(this.amebAwardSupervisor)
        .then(response => {
          this.$snotify.success("Supervisor added");
          this.resetForm();
          this.closeDialog();
          this.loading = false;
          this.$emit('refresh');
        })
        .catch((err) => {
          this.loading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },

    update: function () {
      this.loading = true;
      amebAwardSupervisor
        .update(this.amebAwardSupervisor.id, this.amebAwardSupervisor)
        .then(response => {
          this.$snotify.success("Supervisor updated");
          this.resetForm();
          this.closeDialog();
          this.loading = false;
          this.$emit('refresh');
        })
        .catch((err) => {
          this.loading = false;
          this.errors = err.response.data.errors;
          this.$snotify.error("Oops something went wrong");
        });
    },

    resetForm() {
      this.amebAwardSupervisor = {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        notes: '',
        candidate_id: null
      };
      this.errors = [];
      this.$v.$reset();
    },

    showAmebAwardSupervisor() {
      amebAwardSupervisor
        .show((this.supervisorId))
        .then((res) => {
          this.amebAwardSupervisor = res.data.amebAwardSupervisor;
        })
        .catch((err) => {

        });
    }
  }
}
</script>