<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit status</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-select
                  label="Enrolment status"
                  v-model="candidate.enrolment_status"
                  :error="$v.candidate.enrolment_status.$error"
                  :items="status"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              ></v-select>
              <span class="text-danger" v-if="errors.enrolment_status" >{{errors.enrolment_status[0]}}</span>
              <span class="text-danger" v-if="$v.candidate.enrolment_status.$error">This information is required</span>
            </v-col>


            <v-col cols="12" sm="6" md="12">
              <v-select
                  label="Exam status"
                  v-model="candidate.exam_status"
                  :error="$v.candidate.exam_status.$error"
                  :items="exam_statuses"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              ></v-select>
              <span class="text-danger" v-if="errors.exam_status" >{{errors.exam_status[0]}}</span>
              <span class="text-danger" v-if="$v.candidate.exam_status.$error">This information is required</span>
            </v-col>


            <v-col cols="12" sm="12" md="12"   v-if="candidate.product_type == 'practical_exam'">
              <v-checkbox
                  label="Remove from previous schedules (if any)"
                  v-model="candidate.remove_from_exam_day"
              ></v-checkbox>
<!--              <p>Candidate exam status <b>assessed</b> won't have any impact on candidate schedule when <b>Removed from previous schedule</b>. To change candidate from assessed and make it available for schedule, please contact IT support.</p>-->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            dark
            x-large
            @click="updateCandidate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required} from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";

const candidate = new CandidateService();

export default {
  name: "EditEnrolmentStatus",
  data() {
    return {
      errors: [],
      dialog: false,
      loading: false,
      status: [
        { name: 'Active', value: 'active' },
        { name: 'No Show', value: 'no_show' },
        { name: 'Withdrawn', value: 'withdrawn' },
        { name: 'Void', value: 'void' },
        { name: 'Completed', value: 'completed' },
        { name: 'Cancelled', value: 'cancelled' },
        { name: 'Expired', value: 'expired' },
      ],

      exam_statuses: [
        { name: 'Not scheduled', value: 'not_scheduled' },
        { name: 'Scheduled', value: 'scheduled' },
        { name: 'Pending result', value: 'pending_result' },
        { name: 'Assessed', value: 'assessed' },

      ],
      candidate: {
        enrolment_status: '',
        exam_status: '',
        remove_from_exam_day:false
      }
    }
  },
  validations: {
    candidate: {
      enrolment_status: { required },
      exam_status: { required },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editEnrolmentStatus(item) {
      this.openDialog();
      this.candidate.id = item.id;
      this.candidate.exam_status = item.exam_status;
      this.candidate.product_type = item.product_type;
      this.candidate.enrolment_status = item.enrolment_status;
    },
    updateCandidate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        candidate
            .update(this.candidate.id, this.candidate)
            .then(response => {
              this.$snotify.success("Status updated");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.candidate = {
        enrolment_status:'',
      };
    },
  }
}
</script>

<style scoped>

</style>