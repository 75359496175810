<template>
  <div>
    <v-row v-if="loading">
    <v-col cols="12" >
      <v-skeleton-loader
          type="table-thead">
      </v-skeleton-loader>
      <v-skeleton-loader
          type="table-row-divider@25">
      </v-skeleton-loader>
    </v-col>
    </v-row>
    <v-row  v-if="!loading">
      <v-overlay :value="isDeleting">
        <v-progress-circular
            indeterminate
            color="dark"
        ></v-progress-circular>
      </v-overlay>
      <v-col cols="12" class="text-right">

        <v-btn
            @click="addNote"
            x-large
            dark
        >
          Add note
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="notes.length > 0">
        <table class="table">
          <thead>
          <tr class="text-left">
            <th class="px-3">Title</th>
            <th class="px-3">Priority</th>
            <th class="px-3">Added on</th>
            <th class="px-3">Follow up required</th>
            <th class="px-3">Follow up date</th>
            <th class="px-3">Assigned to</th>
            <th class="px-3">Attachments</th>
            <th class="pr-3 text-center">Options</th>
          </tr>
          </thead>
          <tbody>
          <template>
            <tr v-if="notes.length != 0"
                v-for="(note, index) in notes"
                :key="index">
              <td class="px-2">
                {{ note.name }}
              </td>
              <td class="px-2">
                <strong
                    class="badge badge-primary"
                    v-if="note.priority != 'urgent'"
                >
                  {{note.priority}}
                </strong>
                <strong
                    class="badge badge-danger"
                    v-else
                >
                  {{note.priority}}
                </strong>
              </td>
              <td class="px-2">
                {{ note.created_at }}
              </td>
              <td class="px-2">
                <span class="badge badge-primary">{{note.requires_follow_up ? 'Yes' : 'No'}}</span>
              </td>
              <td class="px-2">
                {{note.formatted_followup_required_date ? note.formatted_followup_required_date : 'NA'}}
              </td>
              <td class="px-2">
                {{note.assigned_to ? note.assigned_to : 'NA'}}
                <small><br/> <b>Added by:</b> {{note.addedBy ? note.addedBy : 'NA'}}</small>
              </td>
              <td class="px-2">
                <div class="py-2" v-if="note.note_attachments && note.note_attachments.length > 0">
                    <span class="mx-1"
                          v-for="(attachment, index) in note.note_attachments"
                          :key="index"
                    >
                            <a
                                v-if="attachment && attachment.file_path"
                                :href="attachment.file_path.original"
                                target="_blank"
                                class="ml-2 mt-2 font-13 font-weight-bold"
                            >
                                <i class="mx-1 fas fa-file blue--text"></i>
                                {{attachment.name}} (File)
                            </a>

                            <a v-else
                               :href="attachment.url"
                               target="_blank"
                               class="font-13 mt-2 font-weight-bold"
                            >
                                <i class="ml-1 fas fa-link blue--text"></i>
                                   {{attachment.name}} (URL)
                            </a>
                      <br/>
                        </span>
                </div>
              </td>
              <td>
                <template>
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item"  v-if="note.requires_follow_up">
                        <a  class="navi-link" @click="noteMarked(note)">
                          <span class="navi-icon">
                              <v-icon color="darken-2">fas fa-close</v-icon>
                          </span>
                          <span class="navi-text">
                              Remove follow up
                          </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a  class="navi-link" @click="editNote(note)">
                          <span class="navi-icon">
                              <v-icon color="darken-2">fas fa-edit</v-icon>
                          </span>
                          <span class="navi-text">
                              Edit
                          </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a  class="navi-link" @click="deleteNote(note.id)">
                          <span class="navi-icon">
                              <v-icon color="darken-2">fas fa-trash</v-icon>
                          </span>
                          <span class="navi-text">
                             Delete
                          </span>
                        </a>
                      </b-dropdown-text>
                    </div>
                  </b-dropdown>
                </template>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </v-col>
      <!--        <v-col -->
      <!--            cols="12" -->
      <!--            md="4" -->
      <!--            v-if="notes.length != 0"-->
      <!--            v-for="(note, index) in notes" -->
      <!--            :key="index"-->
      <!--        >-->
      <!--            <v-card class="rounded-lg" outlined min-height="auto" max-height="auto">-->
      <!--                <v-card-title>-->
      <!--                    {{ note.name }}-->
      <!--                </v-card-title>-->
      <!--                <v-card-text>-->
      <!--                    <div class="d-flex justify-space-between">-->
      <!--                        <div>-->
      <!--                            <strong-->
      <!--                            class="badge badge-primary"-->
      <!--                                v-if="note.priority != 'urgent'"-->
      <!--                            >-->
      <!--                                Priority - {{note.priority}}-->
      <!--                            </strong>-->
      <!--                            <strong-->
      <!--                                class="badge badge-danger"-->
      <!--                                v-else-->
      <!--                            >-->
      <!--                                Priority - {{note.priority}}-->
      <!--                            </strong>-->
      <!--                        </div>-->
      <!--                        <div v-if="note.formatted_followup_required_date" class=""><i class="fa fa-clock blue&#45;&#45;text"></i> {{ note.formatted_followup_required_date }}</div>-->
      <!--                    </div>-->
      <!--                    -->
      <!--                    <div class="breadcrumb mt-3 d-flex justify-content-center">-->
      <!--                      <i class="cursor-pointer ml-2 fas fa-check blue&#45;&#45;text" @click="noteMarked(note)" v-if="note.requires_follow_up"></i>-->
      <!--                      <i class="cursor-pointer mx-2 fas fa-edit blue&#45;&#45;text" @click="editNote(note)"></i>-->
      <!--                      <i class="cursor-pointer fas fa-trash-alt red&#45;&#45;text" @click="deleteNote(note.id)"></i>-->
      <!--                      &lt;!&ndash;                        <i class="cursor-pointer fas fa-plus blue&#45;&#45;text" @click="addNoteFollowup(note.id)" v-if="note.requires_follow_up"></i>&ndash;&gt;-->
      <!--                    </div>-->

      <!--                    <div class="py-2" v-if="note.note_attachments && note.note_attachments.length > 0">-->
      <!--                        <div class="font-size-lg pb-3 font-weight-bold">-->
      <!--                            Attachments-->
      <!--                        </div> -->
      <!--                        <span class="mx-1" -->
      <!--                            v-for="(attachment, index) in note.note_attachments" -->
      <!--                            :key="index"-->
      <!--                        >-->
      <!--                            <a -->
      <!--                                v-if="attachment && attachment.file_path" -->
      <!--                                :href="attachment.file_path.thumb" -->
      <!--                                target="_blank"-->
      <!--                                class="ml-2 mt-2 font-13 font-weight-bold"-->
      <!--                            >-->
      <!--                                <i class="mx-1 fas fa-file blue&#45;&#45;text"></i>-->
      <!--                                File-->
      <!--                            </a>-->
      <!--                            -->
      <!--                            <a v-else -->
      <!--                                :href="attachment.url" -->
      <!--                                target="_blank"-->
      <!--                                class="font-13 mt-2 font-weight-bold"-->
      <!--                            >-->
      <!--                                <i class="ml-1 fas fa-link blue&#45;&#45;text"></i>-->
      <!--                                Link-->
      <!--                            </a>-->
      <!--                        </span>-->
      <!--                    </div>-->

      <!--                    <v-row class="mt-2">-->
      <!--                        <v-col cols="3" v-if="note.note_followups && note.note_followups.length > 0">-->
      <!--                        <b>Note Followups:</b>-->
      <!--                        </v-col>-->
      <!--                        <v-col cols="9" v-if="note.note_followups && note.note_followups.length > 0">-->
      <!--                        <v-expansion-panels>-->
      <!--                            <v-expansion-panel-->
      <!--                                v-for="(followup, index) in note.note_followups" :key="index"-->
      <!--                            >-->
      <!--                            <v-expansion-panel-header>-->
      <!--                                {{ followup.formatted_date }}-->
      <!--                              <b> Added by: </b><span class="ml-1 font-italic">{{ followup.addedBy ? followup.addedBy : 'NA' }} <i class="mx-2 cursor-pointer blue&#45;&#45;text fas fa-trash" @click="deleteFollowup(followup.id)"></i></span>-->
      <!--                            </v-expansion-panel-header>-->
      <!--                            <v-expansion-panel-content class="ml-6 text-justify" v-html="followup.remarks">-->
      <!--                            </v-expansion-panel-content>-->
      <!--                            </v-expansion-panel>-->
      <!--                        </v-expansion-panels>-->
      <!--                        </v-col>-->
      <!--                        <v-col cols="12" md="12">-->
      <!--                        <span><b>Follow up required</b> <span class="badge badge-primary">{{note.requires_follow_up ? 'Yes' : 'No'}}</span></span>-->
      <!--                        </v-col>-->
      <!--&lt;!&ndash;                        <v-col cols="3" md="3">&ndash;&gt;-->
      <!--&lt;!&ndash;                        <span>Is Admin:- <span class="badge badge-primary">{{note.is_admin_only ? 'Yes' : 'No'}}</span></span>&ndash;&gt;-->
      <!--&lt;!&ndash;                        </v-col>&ndash;&gt;-->
      <!--                        <v-col cols="5" md="5">-->
      <!--                        <span><b>Follow up date: </b>{{note.formatted_followup_required_date ? note.formatted_followup_required_date : 'NA'}}</span>-->
      <!--                        </v-col>-->
      <!--                        <v-col cols="4" md="4" class="text-right">-->
      <!--                        <span> <b>Assigned to:</b> {{note.assigned_to ? note.assigned_to : 'NA'}}</span>-->
      <!--                        </v-col>-->
      <!--                    </v-row>-->
      <!--                </v-card-text>-->
      <!--                    -->
      <!--            </v-card>-->
      <!--        </v-col>-->
      <v-col cols="12" v-if="notes.length == 0" class="text-center">
        <div class="border py-8">
          <h5> Notes not available for the candidate </h5>
        </div>
      </v-col>
      <CreateAndUpdate ref="create-and-update" @refresh="getNotes" @getCandidateSummary="getCandidateSummary"></CreateAndUpdate>
      <AddNoteFollowup ref="add-note-followup" @refresh="getNotes"></AddNoteFollowup>

    </v-row>
  </div>

</template>

<script>

import NoteService from "@/services/note/NoteService";
import CreateAndUpdate from "@/view/pages/view/candidate/summary/partials/notes/CreateAndUpdate";
import AddNoteFollowup from "@/view/pages/view/candidate/summary/partials/notes/AddNoteFollowup";
import NoteFollowupService from "@/services/note-followup/NoteFollowupService";

const note = new NoteService();
const noteFollowup = new NoteFollowupService();

export default {
    props:['candidate_id'],
    name: "ShowNotes",
    components: {AddNoteFollowup, CreateAndUpdate},
    data() {
        return {
            notes: [],
            loading: true,
            candidateId: '',
            isDeleting: false,
        }
    },
    mounted() {
        this.getNotes();
    },
    methods: {
        editNote(note) {
            this.$refs['create-and-update'].editNote(note);
        },

        addNote() {
            this.$refs['create-and-update'].addNotes(this.candidate_id);
        },

        addNoteFollowup(id) {
            this.$refs['add-note-followup'].addNoteFollowup(id);
        },

        getNotes() {
            this.loading=true
            note.getCandidateNote(this.candidate_id).then(response => {
                this.notes = response.data.data;
            })
            .finally(() => {
              this.loading=false
            })
                      ;
        },

        noteMarked(item) {
            this.isDeleting = true;
            note
            .noteMarked(item.id, item).then((response) => {
                this.$snotify.success("Note Marked Successfully ");
                this.getNotes();
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            })
            .finally(() => {
                this.isDeleting = false;
            });
        },

        deleteFollowup(id) {
            this.$confirm({
                message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
                if (confirm) {
                    this.isDeleting = true;
                    noteFollowup
                    .delete(id)
                    .then((response) => {
                        this.getNotes();
                        this.$snotify.success("Note followup deleted");
                        this.isDeleting = false;
                    })
                    .catch((err) => {
                        this.isDeleting = false;
                        this.$snotify.error("Oops something went wrong");
                    });
                }
            },
        });
        },
        deleteNote(id) {
            this.$confirm({
                message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
                this.isDeleting = true;
                if (confirm) {
                note
                    .delete(id)
                    .then((response) => {
                        this.$emit('note-deleted');
                        this.$snotify.success("Note deleted");
                        this.getNotes();
                        this.isDeleting = false;
                    })
                    .catch((err) => {
                        this.isDeleting = false;
                        this.$snotify.error("Oops something went wrong");
                    });
                }
            },
        });
        },
        getCandidateSummary(){
            this.$emit('note-created-updated');
        }
    },
  }
</script>
<style scoped>
.text-color {
  color: #646464;
}
.v-item-group {
  position: unset;
}
.font-13{
    font-size: 13px;;
}
</style>