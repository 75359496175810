<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit parent/guardian info</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Parent/guardian first name*"
                  outlined
                  dense
                  :error="$v.enrolment_info.parent_first_name.$error"
                  v-model="enrolment_info.parent_first_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.parent_first_name" >{{errors.parent_first_name[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.parent_first_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Parent/guardian middle name"
                  outlined
                  dense
                  v-model="enrolment_info.parent_middle_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.parent_middle_name" >{{errors.parent_middle_name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Surname*"
                  outlined
                  dense
                  :error="$v.enrolment_info.parent_last_name.$error"
                  v-model="enrolment_info.parent_last_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.parent_last_name" >{{errors.parent_last_name[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.parent_last_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Parent/guardian contact *"
                  outlined
                  dense  @keypress="isNumber($event)"
                  :error="$v.enrolment_info.parent_contact.$error"
                  v-model="enrolment_info.parent_contact"
              ></v-text-field>
              <span class="text-danger" v-if="errors.parent_contact" >{{errors.parent_contact[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.parent_contact.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Parent/guardian email *"
                  outlined
                  dense
                  :error="$v.enrolment_info.parent_email.$error"
                  v-model="enrolment_info.parent_email"
              ></v-text-field>
              <span class="text-danger" v-if="errors.parent_email" >{{errors.email[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.parent_email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.enrolment_info.parent_email.email">Email should be valid</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           dark
           x-large
            @click="updateEnrolmentInfo()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";

const candidateEnrolment = new CandidateEnrolmentInfoService();

export default {
  name: "EditParentInfo",
  data() {
    return {
      errors: [],
      dialog: false,
      loading: false,
      enrolment_info: {
        parent_first_name: '',
        parent_middle_name: '',
        parent_last_name: '',
        parent_contact: '',
        parent_email: '',
      }
    }
  },
  validations: {
    enrolment_info: {
      parent_first_name: { required },
      parent_last_name: { required },
      parent_contact: { required },
      parent_email: { required, email },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editParentInfo(item, editFor) {
      this.openDialog();
      this.title = editFor;
      this.enrolment_info = item;
    },
    updateEnrolmentInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        candidateEnrolment
            .update(this.enrolment_info.id, this.enrolment_info)
            .then(response => {
              this.$snotify.success("Parent/guardian information updated");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.enrolment_info = {
        parent_first_name:'',
        parent_middle_name: '',
        parent_last_name: '',
        parent_contact: '',
        parent_email:'',
      };
    },
  }
}
</script>

<style scoped>

</style>