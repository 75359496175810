<template>
  <v-app>
    <template>
      <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          no-caret
          right
          no-flip
      >
        <template v-slot:button-content>
          <v-btn class="btn btn-primary mr-16" x-large dark>Options</v-btn>
        </template>
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-250px">
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="editCandidate()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-user-edit"></i>
                                  </span>
              <span class="navi-text">Edit details</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a @click="viewAccountHolderInfo(candidate_summary.owner_id)" class="navi-link">
                      <span class="navi-icon">
                          <v-icon color="darken-2">fas fa-user</v-icon>
                      </span>
              <span class="navi-text">View enroller</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link"  @click="editEnrolmentStatus()">
                     <span class="navi-icon">
                         <i class="fas fas fa-arrows"></i>
                      </span>
              <span class="navi-text">Change status</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary && !candidate_summary.is_class_exam &&  candidate_summary.product_type == 'practical_exam'">
            <a class="navi-link" @click="changeExamEnroled()">
                     <span class="navi-icon">
                         <i class="fas fas fa-pencil-alt"></i>
                      </span>
              <span class="navi-text">Edit enrolment</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.product_type == 'practical_exam'">
            <a class="navi-link" @click="changeSchedulingRequest()">
                     <span class="navi-icon">
                         <i class="fas fas fa-pencil-alt"></i>
                      </span>
              <span class="navi-text">Edit scheduling requests</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.product_type != 'practical_exam'">
            <a class="navi-link" @click="editExpiryDate()">
                     <span class="navi-icon">
                         <i class="fas fas fa-user-graduate"></i>
                      </span>
              <span class="navi-text">Change expiry</span>
            </a>
          </b-dropdown-text>

          <hr v-if="candidate_summary.product_type == 'online_exam' && candidate_summary.enrolment_status=='active' && candidate_summary.exam_status=='not_scheduled'"/>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="editExamPin()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-user-shield"></i>
                                  </span>
              <span class="navi-text">Change PIN</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item" >
            <a class="navi-link" @click="resendPurchaseDetails()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-envelope"></i>
                                  </span>
              <span class="navi-text">Resend enrolment confirmation</span>
            </a>
          </b-dropdown-text>


          <hr v-if="(candidate_summary.product_type == 'online_exam' && candidate_summary.enrolment_status=='active') || (candidate_summary.product_type == 'online_exam' && candidate_summary.is_class_exam)">
          <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.product_type == 'online_exam' && candidate_summary.enrolment_status=='active' && candidate_summary.exam_status=='not_scheduled' && candidate_summary.allow_extra_time==0">
            <a class="navi-link" @click="allowExtraTime()">
                     <span class="navi-icon">
                         <i class="fas fas fa-clock"></i>
                      </span>
              <span class="navi-text">Allow extra time</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.product_type == 'online_exam' && candidate_summary.enrolment_status=='active' && candidate_summary.exam_status=='not_scheduled' && candidate_summary.allow_extra_time==1">
            <a class="navi-link" @click="removeExtraTime()">
                     <span class="navi-icon">
                         <i class="fas fas fa-clock"></i>
                      </span>
              <span class="navi-text">Remove extra time</span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.product_type == 'online_exam' && candidate_summary.is_class_exam">
            <a class="navi-link" @click="changeToIndividualExam()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-user-shield"></i>
                                  </span>
              <span class="navi-text">Change to individual exam </span>
            </a>
          </b-dropdown-text>

          <hr v-if="candidate_summary.product_type == 'online_exam'"/>

          <b-dropdown-text tag="div" class="navi-item" 
            v-if="!candidate_summary.product_is_pplatepiano && candidate_summary.product_type=='practical_exam' && candidate_summary.exam_status=='assessed' && candidate_summary.can_preview_certificate && candidate_summary.practical_exam_result_id" >
            <a @click="printDigitalCertificate(candidate_summary.practical_exam_result_id)" class="navi-link">
              <span class="navi-icon">
                  <v-icon color="darken-2">fas fa-user-graduate</v-icon>
              </span>
              <span class="navi-text">Preview print certificate </span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" 
            v-if="candidate_summary.product_is_pplatepiano" >
            <a @click="printPplateCertificate(candidate_summary.exam_key)" class="navi-link">
              <span class="navi-icon">
                  <v-icon color="darken-2">fas fa-user-graduate</v-icon>
              </span>
              <span class="navi-text">Preview print certificate </span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" 
            v-if="candidate_summary.product_type=='ameb_award' && candidate_summary.exam_status=='assessed' && candidate_summary.enrolment_status == 'completed'" >
            <a @click="previewAwardCertificate(candidate_summary.id)" class="navi-link">
              <span class="navi-icon">
                  <v-icon color="darken-2">fas fa-user-graduate</v-icon>
              </span>
              <span class="navi-text">Preview print certificate </span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" 
            v-if="candidate_summary.product_type=='online_exam' && candidate_summary.exam_status=='assessed' && candidate_summary.can_preview_certificate && candidate_summary.official_online_exam_result_id" >
            <a @click="previewOnlineExamPrintCertificate(candidate_summary.official_online_exam_result_id)" class="navi-link">
              <span class="navi-icon">
                  <v-icon color="darken-2">fas fa-user-graduate</v-icon>
              </span>
              <span class="navi-text">Preview print certificate </span>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="openPrintStatus">
                     <span class="navi-icon">
                       <i class="fas fas fa-print"></i>
                     </span>
              <span class="navi-text">Print status</span>
            </a>
          </b-dropdown-text>
          <hr/>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="transferAccountHolder()" >
                                 <span class="navi-icon">
                                     <i class="fas fas fa-arrow-alt-circle-right"></i>
                                  </span>
              <span class="navi-text"> Transfer to new Enroller</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text v-if="appEnv != 'production'" tag="div" class="navi-item">
            <a class="navi-link" @click="shareWithEnroller()" >
                                 <span class="navi-icon">
                                     <i class="fas fas fa-share"></i>
                                  </span>
              <span class="navi-text"> Share with enroller</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.exam_day_id">
            <a class="navi-link" @click="viewSchedule(candidate_summary.exam_day_id)">
                   <span class="navi-icon">
                       <i class="fa fa-solid fa-business-time"></i>
                   </span>
              <span class="navi-text"> Open schedule</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.exam_day_id && candidate_summary.exam_status!='not_scheduled'">
            <a class="navi-link"  @click="viewNotificationLetter(candidate_summary.enrolment_info.exam_day_id, candidate_summary.id)">
                     <span class="navi-icon">
                         <i class="fa fa-calendar-check"></i>
                     </span>
              <span class="navi-text"> Exam notification</span>
            </a>
          </b-dropdown-text>
          <hr v-if="candidate_summary.product_type=='practical_exam' ">
          <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.product_type=='practical_exam' ">
            <a class="navi-link" @click="manageReport(candidate_summary.exam_key)">
                   <span class="navi-icon">
                       <i class="fas fa-bar-chart"></i>
                   </span>
              <span class="navi-text"> View report</span>
            </a>
          </b-dropdown-text>


          <b-dropdown-text tag="div" class="navi-item" v-if="!candidate_summary.is_result_available_to_candidate && candidate_summary.product_type=='practical_exam'">
            <a class="navi-link"   @click="changeCandidateResultAvailability()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-check"></i>
                                  </span>
              <span class="navi-text"> Share report with candidate</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item"   v-if="candidate_summary.is_result_available_to_candidate && candidate_summary.product_type=='practical_exam'">
            <a class="navi-link"  @click="changeCandidateResultAvailability()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-close"></i>
                                  </span>
              <span class="navi-text">  Hide report from candidate</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" v-if="candidate_summary.product_type == 'ameb_award' && candidate_summary.can_performance_contribution_be_submitted && !candidate_summary.enrolment_info.award_is_submitted" class="navi-item" >
            <a class="navi-link" @click="markAwardAsSubmitted(candidate_summary.enrolment_info.id)">
              <span class="navi-icon">
                  <i class="fas fas fa-check-circle"></i>
              </span>
              <span class="navi-text">Mark as submitted</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" v-if="candidate_summary.product_type == 'ameb_award' && candidate_summary.can_performance_contribution_be_submitted && candidate_summary.enrolment_info.award_verification_status != 'verified'" class="navi-item" >
            <a class="navi-link" @click="markAwardAsVerified(candidate_summary.enrolment_info.id)">
              <span class="navi-icon">
                  <i class="fas fas fa-check-double"></i>
              </span>
              <span class="navi-text">Mark as verified</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" v-if="candidate_summary.product_type == 'ameb_award' && candidate_summary.can_performance_contribution_be_submitted && candidate_summary.enrolment_info.award_verification_status != 'rejected'" class="navi-item" >
            <a class="navi-link" @click="markAwardAsNotVerified(candidate_summary.enrolment_info.id)">
              <span class="navi-icon">
                  <i class="fas fas fa-times-circle"></i>
              </span>
              <span class="navi-text">Mark as not granted</span>
            </a>
          </b-dropdown-text>

          <!-- <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.product_type == 'ameb_award'">
            <a class="navi-link" @click="changeAwardSubmissionMethod(candidate_summary.enrolment_info.id, candidate_summary.enrolment_info.award_submission_method)">
              <span class="navi-icon">
                  <i class="fas fas fa-award"></i>
              </span>
              <span class="navi-text"> Change Award submission method </span>
            </a>
          </b-dropdown-text> -->
          <hr/>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="addNotes">
                     <span class="navi-icon">
                       <i class="fas fas fa-plus"></i>
                     </span>
              <span class="navi-text">Add note</span>
            </a>
          </b-dropdown-text>

          <!-- <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="viewNotes">
                   <span class="navi-icon">
                       <i class="fa fa-comment"></i>
                    </span>
              <span class="navi-text"> View notes</span>
            </a>
          </b-dropdown-text> -->

          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" @click="viewInvoice()">
                                 <span class="navi-icon">
                                     <i class="fas fa-file-invoice"></i>
                                  </span>
              <span class="navi-text">View additional invoices</span>
            </a>
          </b-dropdown-text>

<!--          <b-dropdown-text tag="div" class="navi-item"  v-if="! candidate_summary.is_playlist_notified && candidate_summary.product_type=='practical_exam'">-->
<!--            <a class="navi-link" @click="sendRepertoireMail()">-->
<!--                     <span class="navi-icon">-->
<!--                         <i class="fas fa-envelope"></i>-->
<!--                     </span>-->
<!--              <span class="navi-text"> Resend repertoire submission</span>-->
<!--            </a>-->
<!--          </b-dropdown-text>-->

          <b-dropdown-text tag="div" class="navi-item" v-if="!candidate_summary.check_refund_support_ticket_status">
            <a class="navi-link"  @click="requestRefund()">
                                 <span class="navi-icon">
                                     <i class="fas fas fa-dollar"></i>
                                  </span>
              <span class="navi-text" v-if="candidate_summary.can_request_refund">Request refund</span>
              <span class="navi-text" v-else>Request refund</span>
            </a>
          </b-dropdown-text>

          <!--              <b-dropdown-text tag="div" class="navi-item">-->
          <!--                <a class="navi-link" >-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fa-bar-chart"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text"> Add payment</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->


          <hr/>
          <b-dropdown-text tag="div" class="navi-item" >
            <a class="navi-link" @click="goToSupportTicket()">
                                 <span class="navi-icon">
                                     <i class="fa fa-support"></i>
                                  </span>
              <span class="navi-text">View support tickets</span>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.can_authorise_online_exam && candidate_summary.product_type=='online_exam'">
            <a class="navi-link"   @click="manageAuthorizeOnlineExam()">
              <span class="navi-icon">
                <i class="fas fas fa-lock-open"></i>
              </span>
              <span class="navi-text">  {{ candidate_summary.is_class_exam ? 'Authorise class exam' : 'Authorise online exam'}}</span>
            </a>
          </b-dropdown-text>


            <!--              <b-dropdown-text tag="div" class="navi-item" >-->
          <!--                <a class="navi-link" @click="resendCandidateDetails()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-envelope"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">Resend enrolment details</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->

          <!--              <b-dropdown-text tag="div" class="navi-item" >-->
          <!--                <a class="navi-link" @click="resendPurchaseDetails()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-envelope"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">Resend purchase details</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->


          <!--              <b-dropdown-text @click="requestRefund" tag="div" class="navi-item">-->
          <!--                <a class="navi-link">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fa-dollar"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">Request refund</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->


          <!--              <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.product_type=='online_exam'">-->
          <!--                <a class="navi-link" @click="goToSupportOnlineExam()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fa fa-support"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">View exam tickets</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->

          <!--              <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.notification_letter_enabled">-->
          <!--                <a class="navi-link" @click="viewNotificationLetter(candidate_summary.enrolment_info.exam_day_id, candidate_summary.id)"  >-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fa-file-pdf"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">View Timetable </span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->

          <!--              <b-dropdown-text tag="div" class="navi-item"  v-if="candidate_summary.is_result_available_to_user && candidate_summary.product_type=='practical_exam'">-->
          <!--                <a class="navi-link"  @click="changeAccountHolderResultAvailability()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-check"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text"> Make result unavailable to enroler</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->
          <!--              <b-dropdown-text tag="div" class="navi-item"  v-if="!candidate_summary.is_result_available_to_user && candidate_summary.product_type=='practical_exam'">-->
          <!--                <a class="navi-link"   @click="changeAccountHolderResultAvailability()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-check"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text"> Make result available to enroler</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->
          <!--              <b-dropdown-text tag="div" class="navi-item"   v-if="candidate_summary.is_result_available_to_candidate && candidate_summary.product_type=='practical_exam'">-->
          <!--                <a class="navi-link"  @click="changeCandidateResultAvailability()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-check"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">  Make result unavailable to candidate</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->
          <!--              <b-dropdown-text tag="div" class="navi-item" v-if="!candidate_summary.is_result_available_to_candidate && candidate_summary.product_type=='practical_exam'">-->
          <!--                <a class="navi-link"   @click="changeCandidateResultAvailability()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-check"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text"> Make result available to candidate</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->
          <!--              <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.can_authorise_online_exam && candidate_summary.product_type=='online_exam'">-->
          <!--                <a class="navi-link"   @click="manageAuthorizeOnlineExam()">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-lock-open"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">  {{ candidate_summary.is_class_exam ? 'Authorise class exam' : 'Authorise online exam'}}</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->

          <!--              <b-dropdown-text tag="div" class="navi-item" v-if="candidate_summary.product_type!='practical_exam'">-->
          <!--                <a class="navi-link" @click="">-->
          <!--                                 <span class="navi-icon">-->
          <!--                                     <i class="fas fas fa-file-pdf"></i>-->
          <!--                                  </span>-->
          <!--                  <span class="navi-text">Request extension</span>-->
          <!--                </a>-->
          <!--              </b-dropdown-text>-->
        </div>
        <!--end::Navigation-->
      </b-dropdown>
    </template>
    <div>
      <transfer-accountholder ref="transfer-accountHolder"></transfer-accountholder>
      <change-exam-enroled ref="exam-enroled"  @refresh="getCandidateSummary"></change-exam-enroled>
      <edit-scheduling-request ref="exam-requests"  @refresh="getCandidateSummary"></edit-scheduling-request>
      <request-refund ref="request-refund" @refresh="getCandidateSummary"></request-refund>
      <change-award-submission-method ref="change-award-submission-method" @refresh="getCandidateEnrolmentInfo"></change-award-submission-method>
      <mark-as-not-approved ref="mark-not-verified" @refresh="getCandidateEnrolmentInfo"></mark-as-not-approved>
      <share-to-enroler ref="share-to-enroler"></share-to-enroler>
    </div>
  </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import TransferAccountholder from "./partials/transfer/TransferAccountholder";
import EditSchedulingRequest from "@/view/pages/view/candidate/summary/partials/candidate-enrolment-info/EditSchedulingRequest";
import ChangeExamEnroled from "@/view/pages/view/candidate/summary/partials/exam-enroled/ChangeExamEnroled";
import RequestRefund from "@/view/pages/view/candidate/summary/partials/refund/RequestRefund";
import CandidatePracticalExamResultService
  from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
import ChangeAwardSubmissionMethod from "./partials/ameb-award/ChangeAwardSubmissionMethod";
import MarkAsNotApproved from "@/view/pages/view/candidate/summary/partials/ameb-award/MarkAsNotApproved";
import ShareToEnroler from "./partials/candidate-share-request/ShareToEnroler";

const candidate = new CandidateService();
const candidatePracticalExamResult = new CandidatePracticalExamResultService();
const candidateOnlineExamResult = new CandidateOnlineExamResultService();
const candidateEnrolmentInfo = new CandidateEnrolmentInfoService();

export default {
  props: ['productType'],
  components:{
    TransferAccountholder,
    ChangeExamEnroled,
    RequestRefund,
    EditSchedulingRequest,
    ChangeAwardSubmissionMethod,
    MarkAsNotApproved,
    ShareToEnroler
  },
  data(){
    return{
      candidate_summary:{},
      candidate: {
        allow_extra_time: 0
      },
    }
  },
  methods:{
    manageReport(examKey){
      this.$router.push({
        name: 'practical-exam-result-report',
        params: {examKey: examKey}
      })
    },
    transferAccountHolder()
    {
      this.$refs['transfer-accountHolder'].openTransferAccountHolder();
    },
    viewSchedule(examDayId)
    {
      this.$router.push({
        name: 'exam-day-scheduler',
        params: {examDayId: examDayId}
      })
    },
    editCandidate(){
      this.$emit('show-candidate-edit-form')
    },
    changeSchedulingRequest(){
      this.$refs['exam-requests'].openDialog(this.candidate_summary);
    },
    editEnrolmentStatus(){
      this.$emit('show-edit-enrolment-status')
    },
    editExamPin(){
      this.$emit('show-edit-exam-pin')
    },
    editExamStatus(){
      this.$emit('show-edit-exam-status');
    },
    editExpiryDate(){
      this.$emit('show-edit-expiry-date')
    },
    openPrintStatus(){
      this.$emit('print-status')
    },
    addNotes(){
      this.$emit('add-notes')
    },
    viewNotes(){
      this.$emit('view-notes')
    },
    resendCandidateDetails(){
      this.$emit('resend-candidate-detail');
    },
    resendPurchaseDetails(){
      this.$emit('resend-purchase-detail');
    },
    viewCandidateExamResults(){
      this.$emit('view-exam-results');
    },
    viewOnlineCourseResult(){
      this.$emit('view-online-course-results');
    },
    viewInvoice() {
      if(this.candidate_summary.cart_id){
        this.$emit('view-invoice', this.candidate_summary.cart_id);
      }else{
        this.$snotify.error('No invoice found');
      }
    },
    goToSupportTicket() {
      this.$router.push({
        name: "support-ticket-candidate-view",
        params: {candidateId: this.candidate_summary.id }
      })
    },
    goToSupportOnlineExam() {
      this.$router.push({
        name: "support-online-candidate-view",
        params: {candidateId: this.candidate_summary.id }
      })
    },
    getCandidateSummary(){
      candidate
          .getCandidateSummary(this.examKey)
          .then((response) => {
            this.candidate_summary = response.data.candidate;
          })
          .catch((err) => {
          })
          .finally( () => {

          })
    },
    changeAccountHolderResultAvailability(){
      this.$emit('change-accountholder-result-availability');
    },
    changeCandidateResultAvailability(){
      this.$emit('change-candidate-result-availability');
    },
    manageAuthorizeOnlineExam(){
      if(this.candidate_summary && this.candidate_summary.can_authorise_online_exam){
        this.$emit('manage-authorize-online-exam');
      }

    },

    viewNotificationLetter(examDayId, candidateId){
      candidate
          .viewNotificationLetter(examDayId, candidateId);
    },
    changeExamEnroled(){
      this.$refs['exam-enroled'].openDialog(this.candidate_summary);
    },
    allowExtraTime(){
      this.$confirm({
        message: `Are you sure you want to allow extra time for official exam?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loadReminderMail = true;
            this.candidate.allow_extra_time =1;
            this.candidate.id =this.candidate_summary.id;
            this.loading = true;
            candidate
                .update(this.candidate.id, this.candidate)
                .then(response => {
                  this.$snotify.success("Extra time granted");
                  this.candidate_summary = response.data.candidate;
                  this.$emit('refresh');
                })
                .catch((err) => {
                  this.loading = false;
                  this.errors = err.response.data.errors;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
    removeExtraTime(){
      this.$confirm({
        message: `Are you sure you want to remove extra time for official exam?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loadReminderMail = true;
            this.candidate.allow_extra_time = 0;
            this.candidate.id =this.candidate_summary.id;
            this.loading = true;
            candidate
                .update(this.candidate.id, this.candidate)
                .then(response => {
                  this.loading = false;
                  this.$snotify.success("Extra time removed");
                  this.candidate_summary = response.data.candidate;
                })
                .catch((err) => {
                  this.loading = false;
                  this.errors = err.response.data.errors;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: 'accountholder-edit',
        params: {accountholderId: accountHolderId}
      })
    },
    requestRefund(){
      this.$refs['request-refund'].requestRefund(this.candidate_summary);
    },
    sendRepertoireMail(){
      this.$confirm({
        message: `Are you sure you want to send reminder email?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loadReminderMail = true;
            candidatePlayList
                .sendRepertoireMail(this.examKey)
                .then((response) => {
                  this.$snotify.success(response.data.message);
                  this.getCandidateSummary();
                  this.loadReminderMail = false;
                })
                .catch((err) => {
                  this.$snotify.error(err.errors);
                  this.loadReminderMail = false;
                  this.getCandidateSummary();
                })
                .finally(() => {

                });
          }
        }
      });
    },

    changeToIndividualExam (){
      this.$confirm({
        message: `Are you sure you want to change to individual exam ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let candidateSummary = {};
            candidateSummary.is_class_exam = 0;
            candidateSummary.class_exam_identifier = null;
            candidate
                .update(this.candidate_summary.id, candidateSummary)
                .then(() => {
                  this.$snotify.success("Update successfully !!");
                  this.getCandidateSummary();
                })
                .catch(() => {});
          }
        }
      });
    },

    printDigitalCertificate(reportId){
        candidatePracticalExamResult
          .previewPrintCertificate(reportId)
    },

    printPplateCertificate(examKey){
      candidate
      .previewPplateCertificate(examKey);
    },

    previewOnlineExamPrintCertificate(resultId){
        candidateOnlineExamResult
          .getDigitalCertificatePdf(resultId);
    },
    markAwardAsSubmitted(enrolmentInfoId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidateEnrolmentInfo
            .markAwardAsSubmitted(enrolmentInfoId)
            .then((res) => {
              this.$snotify.success("Marked as submitted");
              this.$emit('refresh');
            })
            .catch((err) => {
              this.$snotify.success("Something went wrong");
            })
          }
        },
      });
    },
    markAwardAsVerified(enrolmentInfoId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidateEnrolmentInfo
            .markAwardAsVerified(enrolmentInfoId)
            .then((res) => {
              this.$snotify.success("Marked as verified");
              this.$emit('refresh');
            })
            .catch((err) => {
              this.$snotify.success("Something went wrong");
            })
          }
        },
      });
    },
    markAwardAsNotVerified(enrolmentInfoId){
      this.$refs['mark-not-verified'].openDialog(enrolmentInfoId);
    },
    changeAwardSubmissionMethod(enrolmentInfoId, awardSubmissionMethod){
      this.$refs['change-award-submission-method'].openDialog(enrolmentInfoId, awardSubmissionMethod);
    },
    getCandidateEnrolmentInfo(){
      this.$emit('refresh');
    },
    previewAwardCertificate(candidateId){
      candidate
      .previewCertificatePdf(candidateId);
    },
    shareWithEnroller(){
      this.$refs['share-to-enroler'].openDialog(this.candidate_summary.id);
    }
  },
  computed: {
    examKey() {
      return this.$route.params.examKey;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  },
  mounted() {
    this.getCandidateSummary();
  }

}
</script>
