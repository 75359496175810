<template>
    <v-dialog 
        v-model="dialog"
        max-width="800px"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>View contribution details</span>
                <hr />
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                          type="table-thead"
                          v-if="loading"
                      >
                      </v-skeleton-loader>
                      <v-skeleton-loader
                          v-if="loading"
                          type="table-row-divider@5"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                    <v-row v-if="!loading">
                      <v-col cols="4">
                        <b>Title</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{contribution.title ? contribution.title : 'NA'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Description</b>
                      </v-col>
                      <v-col v-if="contribution.description" cols="12">
                        <span v-html="contribution.description"></span>
                      </v-col>
                      <v-col v-else cols="8">
                        <span>NA</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Uploaded file</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-if="contribution.ameb_award_reflection_file_path">
                          <a :href="contribution.ameb_award_reflection_file_path.original" target="_blank">
                            View file
                          </a>
                        </div>
                        <div v-else>NA</div>
                      </v-col>

                      <v-col cols="4">
                        <b>Category</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-if="contribution.category_formatted" v-html="contribution.category_formatted"></div>
                        <div v-else>NA</div>
                      </v-col>
                      
                      <v-col cols="4" v-if="contribution.dates_formatted">
                        <b>Dates</b>
                      </v-col>
                      <v-col cols="8" v-if="contribution.dates_formatted">
                        <span>{{contribution.dates_formatted[0]}} - {{ contribution.dates_formatted[1] }}</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Venue details</b>
                      </v-col>
                      <v-col cols="8">
                        <span v-if="contribution.venue_details" v-html="contribution.venue_details"></span>
                        <span v-else>NA</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Assigned supervisor</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{contribution.assigned_supervisor_full_name ? contribution.assigned_supervisor_full_name : 'NA'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Substantial contribution nomination</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-html="contribution.nominated_as_substantial_contribution_badge"></div>
                      </v-col>
                      <v-col cols="4" v-if="contribution.nominated_as_substantial_contribution">
                        <b>Substantial contribution</b>
                      </v-col>
                      <v-col cols="8" v-if="contribution.nominated_as_substantial_contribution">
                        <div v-html="contribution.substantial_contribution_description"></div>
                      </v-col>
                      <v-col cols="4">
                        <b>Assigned supervisor email</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{contribution.assigned_supervisor_email ? contribution.assigned_supervisor_email : 'NA'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Supervisor notified</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{contribution.is_supervisor_notified ? 'Yes' : 'No'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Status</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-html="contribution.status_badge"></div>
                      </v-col>
                      <v-col cols="4" v-if="contribution.status=='declined'">
                        <b>Supervisor declined date</b>
                      </v-col>
                      <v-col cols="8" v-if="contribution.status=='declined'">
                        <span>{{contribution.formatted_approved_declined_date}}</span>
                      </v-col>
                      <v-col cols="4" v-if="contribution.status=='declined'">
                        <b>Supervisor declined remarks</b>
                      </v-col>
                      <v-col cols="8" v-if="contribution.status=='declined'">
                        <span>{{contribution.declined_remarks}}</span>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    x-large
                    @click="closeDialog">
                Cancel
                </v-btn>


            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AwardContributionService from "@/services/candidate/ameb-award/award-contribution/AwardContributionService";
    const contributionService = new AwardContributionService();

    export default{
        data(){
            return{
                contributionId: null,
                contribution: null,
                dialog: false,
                loading: true
            }
        },
        methods:{
            openDialog(contributionId){
              this.dialog = true;
              this.loading = true;
              contributionService
                  .viewDetail(contributionId)
                  .then((response) => {
                    this.contribution = response.data.data
                  })
                  .catch((err) => {
                    this.$snotify.error("Oops something went wrong");
                  })
                  .finally(() => {
                    this.loading = false;
                  })
            },
            closeDialog(){
                this.dialog = false;
            },

        }
    }
</script>