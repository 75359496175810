<template>
    <v-dialog v-model="dialog" scrollable max-width="800">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>{{ title }} detail</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field v-model="awardContribution.title" :error="$v.awardContribution.title.$error"
                                outlined dense>
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.awardContribution.title.$error">This information is
                                required</span>
                            <span class="text-danger" v-if="errors.title">* {{ errors.first_name[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            Description <span class="text-danger">*</span>
                            <ckeditor :config="getEditorConfig()" v-model="awardContribution.description">
                            </ckeditor>
                            <span class="text-danger" v-if="errors.description">
                                {{ errors.description[0] }}
                            </span>
                            <span class="text-danger" v-if="$v.awardContribution.description.$error">This information is
                                required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-autocomplete outlined dense multiple :error="$v.awardContribution.category.$error"
                                :items="categories" :menu-props="{ minWidth: '30em' }" item-text="name" item-value="value"
                                placeholder="Select categories" v-model="awardContribution.category">
                                <template v-slot:label>Category <span class="text-danger">*</span></template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="errors.category">
                                {{ errors.category[0] }}
                            </span>
                            <span class="text-danger" v-if="$v.awardContribution.category.$error">This information is
                                required</span>
                        </v-col>

                        <v-col cols="12">
                            Dates <span class="text-danger">*</span>
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateRangeText" label="Select date or range of dates"
                                        prepend-icon="" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                        v-on:click:prepend-inner="menu = true" outlined dense
                                        :error="$v.awardContribution.dates.$error"></v-text-field>
                                </template>
                                <v-date-picker v-model="dates" no-title @input="menu = false" range></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.awardContribution.dates.$error">This information is
                                required</span>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-3">
                            Venue name and address <span class="text-danger">*</span>
                            <ckeditor :config="getEditorConfig()" v-model="awardContribution.venue_details">
                            </ckeditor>
                            <span class="text-danger" v-if="$v.awardContribution.venue_details.$error">This information is
                                required</span>
                            <span class="text-danger" v-if="errors.venue_details">* {{ errors.venue_details[0] }}</span>
                        </v-col>

                        <v-col v-if="has_substantial_contribution">
                            Is this contribution activity nominated as your ‘substantial contribution’?
                            <v-switch v-model="awardContribution.nominated_as_substantial_contribution"
                                :label="awardContribution.nominated_as_substantial_contribution ? 'Yes' : 'No'"></v-switch>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-3"
                            v-if="awardContribution.nominated_as_substantial_contribution">
                            After you’ve completed your substantial activity, please provide a short reflection on this
                            contribution. Did everything go to plan (it doesn’t matter if it didn’t)? What would you do
                            differently next time?
                            How do you think your efforts helped the other people involved in the project/event/performance?
                            What have you learnt that will help you with your future endeavours
                            in the performing arts sector. <span class="text-danger">*</span>
                            <ckeditor :config="getEditorConfig()"
                                v-model="awardContribution.substantial_contribution_description">
                            </ckeditor>
                            <span class="text-danger"
                                v-if="$v.awardContribution.substantial_contribution_description.$error">This information is
                                required</span>
                            <span class="text-danger" v-if="errors.substantial_contribution_description">* {{
                                errors.substantial_contribution_description[0] }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn x-large text @click="closeDialog">
                    Cancel
                </v-btn>
                <v-btn dark x-large @click="createOrUpdate()" :loading="loading">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import AwardContributionService from "@/services/candidate/ameb-award/award-contribution/AwardContributionService";
import { required, requiredIf } from "vuelidate/lib/validators";

const awardContribution = new AwardContributionService();

export default {
    validations: {
        awardContribution: {
            title: { required },
            description: { required },
            category: { required },
            dates: { required },
            venue_details: { required },
            nominated_as_substantial_contribution: {},
            substantial_contribution_description: {
                required: requiredIf(function (nestedModel) {
                    return this.awardContribution.nominated_as_substantial_contribution;
                })
            }
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            title: '',
            menu: false,
            has_substantial_contribution: '',
            awardContribution: {
                title: '',
                description: '',
                category: [],
                dates: '',
                venue_details: '',
                nominated_as_substantial_contribution: 0,
                substantial_contribution_description: ''
            },
            dates: [],
            errors: [],
            awardContributionId: null,
            categories: [
                {
                    name: 'Assistance & Support',
                    value: 'assistance_and_support'
                },
                {
                    name: 'Leadership',
                    value: 'leadership'
                },
                {
                    name: 'Community',
                    value: 'community'
                },
                {
                    name: 'Creativity',
                    value: 'creativity'
                },
                {
                    name: 'Entrepreneurship',
                    value: 'entrepreneurship'
                },
                {
                    name: 'Collaboration',
                    value: 'collaboration'
                },
            ],
        }
    },
    methods: {
        addDetail(awardContribution, has_substantial_contribution) {
            this.awardContribution = awardContribution;
            this.dates = [];

            this.title = 'Add';
            this.awardContributionId = awardContribution.id;
            this.edit = false;
            this.openDialog();
            this.has_substantial_contribution = has_substantial_contribution;
        },
        editDetail(awardContribution, has_substantial_contribution) {
            this.awardContribution = awardContribution;
            this.dates = [];
            if (this.awardContribution.dates) {
                // this.dates = this.awardContribution.dates_formatted;
                    this.dates.push(this.awardContribution.dates);
            }

            this.title = 'Edit';
            this.awardContributionId = awardContribution.id;
            this.edit = false;
            this.openDialog();
            this.has_substantial_contribution = has_substantial_contribution;
        },
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            // this.resetForm();
            this.dialog = false;
        },
        createOrUpdate() {
            this.awardContribution.dates = this.dates.join(',');
            this.$v.$touch();
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 60000);
            } else {
                this.loading = true;
                this.updateAwardContribution();
            }
        },
        updateAwardContribution() {
            awardContribution
                .update(this.awardContributionId, this.awardContribution)
                .then((response) => {
                    this.$snotify.success(`Details updated`);
                    this.$emit('refresh');
                    this.loading = false;
                    this.closeDialog();
                })
                .catch((err) => {
                    this.errors = err.errors;
                    this.loading = false;
                })
                .finally(() => {

                });
        },
        resetForm() {
            this.awardContribution = {
                title: '',
                description: '',
                dates: '',
                venue_details: ''
            };
            this.errors = [];
            this.$v.$reset();
        },
        getEditorConfig() {
            return {
                toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'SpellChecker', 'Scayt', 'RemoveFormat']
                ],
                scayt_sLang: 'en_GB',
                scayt_autoStartup: true,
                allowedContent: true,
                scayt_disableOptionsStorage: 'lang',
                versionCheck: false
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },
}
</script>