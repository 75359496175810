import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateTransferCommentService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/candidate-transfer-comment';
    }

    getByCandidate(candidateId){
        let url = `${this.#api}/${candidateId}/get-by-candidate`;
        return apiService.get(url);
    }
}