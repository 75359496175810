<template>
    <v-dialog 
        v-model="dialog"
        max-width="800px"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>Change award submission method</span>
                <hr />
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="enrolmentInfo.award_submission_method"
                                :items=awardSubmissionMethods
                                item-text="name"
                                item-value="value"
                                outlined
                                dense
                            >   
                                <template
                                    v-slot:label
                                >
                                Select award submission method <span class="text-danger">*</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    x-large
                    @click="closeDialog">
                Cancel
                </v-btn>
                <v-btn
                    dark
                    x-large
                    @click="updateSubmissionMethod()"
                    :loading="loading"
                >
                Save
                </v-btn>


            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
    
    const enrolmentService = new CandidateEnrolmentInfoService();

    export default{
        data(){
            return{
                enrolmentInfoId: null,
                dialog: false,
                enrolmentInfo:{
                    award_submission_method: ''
                },
                awardSubmissionMethods:[
                    {
                        name: 'Digital',
                        value: 'digital'
                    },
                    {
                        name: 'Postal',
                        value: 'postal'
                    }
                ],
                loading: false
            }
        },
        methods:{
            openDialog(enrolmentInfoId, awardSubmissionMethod){
                this.enrolmentInfo = {
                    award_submission_method : awardSubmissionMethod
                }
                this.enrolmentInfoId = enrolmentInfoId;
                this.dialog = true;
            },
            closeDialog(){
                this.dialog = false;
            },
            updateSubmissionMethod(){
                this.loading = true;

                enrolmentService
                .updateSubmissionMethod(this.enrolmentInfoId, this.enrolmentInfo)
                .then((res) => {
                    this.$snotify.success("Award submission method changed.");
                    this.$emit('refresh');
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>