<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit teacher info</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="First name*"
                  outlined
                  dense
                  :error="$v.enrolment_info.teacher_first_name.$error"
                  v-model="enrolment_info.teacher_first_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.teacher_first_name" >{{errors.teacher_first_name[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.teacher_first_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Middle name"
                  outlined
                  dense
                  v-model="enrolment_info.teacher_middle_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.teacher_middle_name" >{{errors.teacher_middle_name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Surname*"
                  outlined
                  dense
                  :error="$v.enrolment_info.teacher_last_name.$error"
                  v-model="enrolment_info.teacher_last_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.teacher_last_name" >{{errors.teacher_last_name[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.teacher_last_name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Contact *" @keypress="isNumber($event)"
                  type="text"
                  outlined
                  dense
                  :error="$v.enrolment_info.teacher_contact.$error"
                  v-model="enrolment_info.teacher_contact"
              ></v-text-field>
              <span class="text-danger" v-if="errors.teacher_contact" >{{errors.teacher_contact[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.teacher_contact.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                  label="Email *"
                  outlined
                  dense
                  :error="$v.enrolment_info.teacher_email.$error"
                  v-model="enrolment_info.teacher_email"
              ></v-text-field>
              <span class="text-danger" v-if="errors.teacher_email" >{{errors.email[0]}}</span>
              <span class="text-danger" v-if="$v.enrolment_info.teacher_email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.enrolment_info.teacher_email.email">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
           x-large
           text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           dark
           x-large
            @click="updateEnrolmentInfo()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";

const candidateEnrolment = new CandidateEnrolmentInfoService();
export default {
  name: "EditTeacherInfo",
  data() {
    return {
      errors: [],
      dialog: false,
      loading: false,
      enrolment_info: {
        teacher_first_name: '',
        teacher_middle_name: '',
        teacher_last_name: '',
        teacher_contact: '',
        teacher_email: '',
      }
    }
  },
  validations: {
    enrolment_info: {
      teacher_first_name: { required },
      teacher_last_name: { required },
      teacher_contact: { required },
      teacher_email: { required, email },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editTeacherInfo(item) {
      this.openDialog();
      this.enrolment_info = item;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateEnrolmentInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        candidateEnrolment
            .update(this.enrolment_info.id, this.enrolment_info)
            .then(response => {
              this.$snotify.success("Teacher info updated");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.enrolment_info = {
        teacher_first_name:'',
        teacher_middle_name: '',
        teacher_last_name: '',
        teacher_contact: '',
        teacher_email:'',
      };
    },
  }
}
</script>

<style scoped>

</style>