<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>Edit Repertoire playlist</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <div v-if="hasCandidatePlaylist" class="pt-4 text-right">
                    <v-btn  
                        @click="addFieldColumn"
                        class="btn text-white btn-primary"
                        small
                    ><i class="fa fa-plus"></i> Add piece</v-btn>
                </div>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row
                        v-for="(row, index) in fields" 
                        :key="index" 
                        class="mb-3"
                        >
                            <v-col cols="12" md="4">
                                <label for="#">Piece Type <span class="text-danger">*</span> </label>
                                <v-select
                                    outlined
                                    dense
                                    v-model="row.type"
                                    :items="temp_types"
                                    @change="getTypes"
                                    item-text="name"
                                    item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <label for="#">Composer <span class="text-danger">*</span> </label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="row.composer"
                                    label="Composer"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <label for="#">Piece <span class="text-danger">*</span> </label>
                                <div class="d-flex justify-content-between">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="row.title_of_piece"
                                        label="Piece"
                                    ></v-text-field>
                                    <v-btn
                                        v-if="index || (!index && fields.length > 1)"
                                        @click="removeFieldColumn(index)"
                                        small
                                        color="red lighten-1"
                                        class="text-white mt-2 ml-3"
                                    >
                                        <i class="fas fa-trash" style="color: #ffffff"></i>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    @click="closeDialog"
                                   x-large
                                    text
                                >Close</v-btn>
                                <v-btn
                                    @click="saveCandidatePlaylist"
                                   dark
                                    x-large
                                    :loading="loading"
                                >Save</v-btn>
                            </v-col>
                        </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
const candidatePlayList = new CandidatePlayListService();

export default {
    data(){
        return {
            dialog: false,
            examKey: null,
            hasCandidatePlaylist: false,
            showPlaylistForm: false,
            fields:[{
                type:'',
                composer:'',
                title_of_piece:'',
            }],
            candidate_playlist:{
                type:[],
                composer:[],
                title_of_piece:[],
                exam_key: null,
            },
            rows:[],
            types:[
                {header: 'Traditional'},
                {name: 'List A', value: 'List A'},
                {name: 'List B', value: 'List B'},
                {name: 'List C', value: 'List C'},
                {name: 'List D', value: 'List D'},
                {name: 'List E', value: 'List E'},
                {name: 'Extra List', value: 'Extra List'},
                {name: 'Canon (Old Syllabus Piano Only)', value: 'Canon (Old Syllabus Piano Only)'},
                {name: 'Own Choice (Repertoire exams)', value: 'Own Choice (Repertoire exams)'},

                {header: 'For Leisure'},
                {name: 'List Piece', value: 'List Piece'},
                {name: 'Own Choice', value: 'Own Choice'},

                {header: 'Rockschool'},
                {name: 'Set Piece', value: 'Set Piece'},
                {name: 'Free Choice Piece', value: 'Free Choice Piece'},
            ],
            loading: false,
            temp_types: [],
        }
    },
    methods:{
        addFieldColumn(){
            this.fields.push({
                type:'',
                composer:'',
                title_of_piece:'',
            });
        },
        removeFieldColumn(index){
            this.fields.splice(index, 1);
        },
        buildPlayListData(){
            let type =[];
            let composer = [];
            let title_of_piece = [];

            this.fields.forEach((field)=>{
                if(field.type)
                  type.push(field.type);
                if(field.composer)
                  composer.push(field.composer);
                if(field.title_of_piece)
                  title_of_piece.push(field.title_of_piece);
            });

            this.candidate_playlist.type = type;
            this.candidate_playlist.composer = composer;
            this.candidate_playlist.title_of_piece = title_of_piece;
            this.candidate_playlist.exam_key = this.examKey;
        },
        closeDialog(){
            this.dialog = false;
            this.examKey = null,
            this.hasCandidatePlaylist = false,
            this.showPlaylistForm = false,
            this.fields = [{
                type:'',
                composer:'',
                title_of_piece:'',
            }];
            this.candidate_playlist = {
                type:[],
                composer:[],
                title_of_piece:[],
                exam_key: null,
            };
            this.rows = [];
            this.temp_types = [];

        },
        openDialog(examKey){
            this.dialog = true;
            this.examKey = examKey;
            this.getCandidatePlaylists();
        },
        getCandidatePlaylists(){
            candidatePlayList
            .getByExamKey(this.examKey)
            .then((response) => {
                if(response.data.status == 'ERROR'){
                    this.hasCandidatePlaylist = false;
                    this.showPlaylistForm = true;
                }else{
                    this.hasCandidatePlaylist = true;
                    this.showPlaylistForm = false;
                    this.candidatePlaylistId = response.data.data.id;
                    
                    let composer = response.data.data.composer;
                    let title_of_piece = response.data.data.title_of_piece;
                    let type = response.data.data.type;

                    this.buildDataForEdit(composer, title_of_piece, type);
                }
            })
            .catch((err) => {
                this.hasCandidatePlaylist = false;
            })
            .finally(() => {
                
            });
        },
        buildDataForEdit(composer, title_of_piece, type){
            this.fields = [];

            let length = composer.length;
            for(var i=0; i<length; i++){
                this.fields.push(
                    {
                        type:type[i],
                        composer:composer[i],
                        title_of_piece:title_of_piece[i]
                    }
                )
            }
            this.getTypes();
        },
        saveCandidatePlaylist(){
            this.loading = true;
            this.buildPlayListData();
            candidatePlayList
            .create(this.candidate_playlist)
            .then((response) => {
                this.$snotify.success('Repertoire playlist added');
                this.loading = false;
                this.edit = false;
                this.getCandidatePlaylists();
                this.closeDialog();
                this.$emit('refresh');
            })
            .catch((err) => {
                this.$snotify.error('Oops we could not add a plylist.');
                this.loading = false;
                this.closeDialog();
            })
            .finally(() => {
                this.loading = false;
            });
        },
        getTypes(){
            let type = this.types;
            let selected = [];

            selected = this.fields.map((item) => {
                return item.type;
            });

            if(selected.length > 0){
                    type.forEach((x, i) => {
                        if(selected.includes(x.value)){
                            x.disable=true;
                        }else{
                            x.disable=false;
                        }
                    })
            }
            this.temp_types = type;
        }
    },
}
</script>
