<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="600"
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Update status</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="6" md="12">
                            <v-select
                                outlined
                                dense
                                :items="statuses"
                                item-text="name"
                                item-name="value"
                                v-model="performance.status"
                            >
                                <template v-slot:label>
                                    Status <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <!-- <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span> -->
                            <!-- <span class="text-danger" v-if="$v.grade.name.$error">This information is required</span> -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    x-large
                    @click="updatePerformance()"
                    :loading="loading"
                >
                    Save
                </v-btn>
                <v-btn
                    x-large
                    text
                    @click="closeDialog"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>    
    import AwardPerformanceService from "@/services/candidate/ameb-award/award-performance/AwardPerformanceService";

    const awardPerformance = new AwardPerformanceService();

    export default {
        data(){
            return{
                dialog: false,
                performance:{
                    status: ''
                },
                statuses:[
                    {
                        name: 'Pending',
                        value: 'pending'
                    },
                    {
                        name: 'Approve',
                        value: 'approved'
                    },
                    {
                        name: 'Decline',
                        value: 'declined'
                    },
                ],
                loading: false
            }
        },
        methods:{
            closeDialog(){
                this.dialog = false;
            },
            openDialog(id, status) {
                this.id = id;
                this.performance.status = status;
                this.dialog = true;
            },
            updatePerformance(){
                this.loading = true;

                awardPerformance
                .changeStatus(this.id, this.performance)
                .then((response) => {
                    this.$snotify.success(`Details updated`);
                    this.$emit('refresh');
                    this.loading = false;
                    this.closeDialog();
                })
                .catch((err) => {
                    this.errors = err.errors;
                    this.loading = false;
                })
                .finally(() => {

                });
            }
        }
    }
</script>