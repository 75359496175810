<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Update print status</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Certificate print eligibility
              <v-switch
                  v-model="candidate.certificate_print_eligibility"
                  :label="candidate.certificate_print_eligibility ? 'Yes' : 'No'"
                  color="indigo darken-3"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              Is certificate printed
              <v-switch
                  v-model="candidate.is_certificate_printed"
                  @change="changePrintDate"
                  :label="candidate.is_certificate_printed ? 'Yes' : 'No'"
                  color="indigo darken-3"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="candidate.certificate_printed_date"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Certificate printed date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="candidate.certificate_printed_date"
                    no-title
                    @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            @click="closeDialog"
            text
            x-large>
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="update()"
            :loading="loading"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import CandidateService from "@/services/candidate/CandidateService";

const candidate = new CandidateService();
export default {
  name: "PrintStatus",
  data() {
    return {
      menu: false,
      dialog: false,
      loading: false,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      candidate: {
        id: '',
        certificate_print_eligibility: '',
        is_certificate_printed: '',
        certificate_printed_date: '',
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.candidate = {
        id: '',
        certificate_print_eligibility: '',
        is_certificate_printed: '',
        certificate_printed_date: '',
      };
    },
    openDialog(candidate) {
      this.dialog = true;
      this.candidate.id = candidate.id;
      this.candidate.certificate_print_eligibility = candidate.certificate_print_eligibility;
      this.candidate.is_certificate_printed = candidate.is_certificate_printed;
      this.candidate.certificate_printed_date = candidate.certificate_printed_date ? candidate.certificate_printed_date : this.currentDate;
    },

    changePrintDate() {
      if(!this.candidate.is_certificate_printed) {
        this.candidate.certificate_printed_date = null;
      } else {
        this.candidate.certificate_printed_date = this.currentDate;
      }
    },

    update() {
      candidate
          .update(this.candidate.id, this.candidate)
          .then(response => {
            this.$snotify.success("Print status updated");
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(() => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    }
  }
}
</script>
