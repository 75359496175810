<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="700"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span> Candidate attachment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog=false">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="candidate_attachment.title"
                  :error="$v.candidate_attachment.title.$error"
                  outlined
                  dense
              >
                <template slot="label">
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title" >{{errors.title[0]}}</span>
              <span class="text-danger" v-if="$v.candidate_attachment.title.$error" >This information is required</span>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                  v-model="candidate_attachment.type"
                  outlined
                  dense
                  :items="types"
                  item-value="value"
                  item-text="name"
                  :error="$v.candidate_attachment.type.$error"
              >
                <template slot="label">
                  Type <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="errors.type" >{{errors.type[0]}}</span>
              <span class="text-danger" v-if="$v.candidate_attachment.type.$error" >This information is required</span>
            </v-col>
            <v-col cols="12" md="12" v-if="!edit">
              <v-autocomplete
                  v-model="candidate_attachment.attachment_type"
                  outlined
                  dense
                  :items="attachment_types"
                  item-value="value"
                  item-text="name"
                  :error="$v.candidate_attachment.attachment_type.$error"
              >
                <template slot="label">
                  Attachment type <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="errors.attachment_type" >{{errors.attachment_type[0]}}</span>
              <span class="text-danger" v-if="$v.candidate_attachment.attachment_type.$error" >This information is required</span>
            </v-col>
            <v-col v-if="candidate_attachment.attachment_type == 'file' && !edit" cols="12" md="12">
              <v-file-input
                  outlined
                  dense
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  v-model="candidate_attachment.uploadFile"
                  :error="$v.candidate_attachment.uploadFile.$error"
              >
                <template slot="label">
                  File <span class="text-danger">*</span>
                </template>
              </v-file-input>
              <span class="text-danger" v-if="errors.uploadFile" >{{errors.uploadFile[0]}}</span>
              <span class="text-danger" v-if="$v.candidate_attachment.uploadFile.$error" >This information is required</span>
            </v-col>
            <v-col cols="12" md="12" v-if="candidate_attachment.attachment_type == 'url' && !edit">
              <v-text-field
                  outlined
                  dense
                  v-model="candidate_attachment.url"
                  :error="$v.candidate_attachment.url.$error"
              >
                <template slot="label">
                  Link <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.url" >{{errors.url[0]}}</span>
              <span class="text-danger" v-if="$v.candidate_attachment.url.$error" >This information is required</span>
            </v-col>
            <!--            <v-col cols="12" md="12">-->
            <!--              Description <span class="text-danger">*</span>-->
            <!--              <ckeditor-->
            <!--                  :config="editorConfig"-->
            <!--                  v-model="candidate_attachment.description"-->
            <!--              >-->
            <!--              </ckeditor>-->
            <!--                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>-->
            <!--                <span class="text-danger" v-if="$v.candidate_attachment.description.$error" >This information is required</span>-->
            <!--            </v-col>-->
            <v-col cols="12" md="6">
              <div>Available to examiner?</div>
              <v-radio-group
                  v-model="candidate_attachment.available_to_examiner"
                  row
              >
                <v-radio
                    label="Yes"
                    value="1"
                ></v-radio>
                <v-radio
                    label="No"
                    value="0"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <div>Make available to enroller?</div>
              <v-radio-group
                  v-model="candidate_attachment.available_to_account_holder"
                  row
              >
                <v-radio
                    label="Yes"
                    value="1"
                ></v-radio>
                <v-radio
                    label="No"
                    value="0"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="uploadFile()"
            :loading="loading"
        >
          {{ edit ? 'Update' : 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import CandidateAttachmentService from "@/services/candidate/attachment/CandidateAttachmentService";

const attachment = new CandidateAttachmentService();
export default {
  validations: {
    candidate_attachment: {
      title: {
        required
      },
      type: {
        required
      },
      attachment_type: {
        required
      },
      uploadFile: {
        required: requiredIf(function () {
          return this.candidate_attachment.url == '';
        })
      },
      url: {
        required: requiredIf(function () {
          return this.candidate_attachment.uploadFile == '';
        })
      }
    }
  },
  data() {
    return {
      errors: [],
      dialog: false,
      edit: false,
      loading: false,
      candidate_attachment: {
        title: '',
        candidate_id: '',
        uploaded_by_type: 'admin',
        uploaded_by: '',
        type: 'other',
        attachment_type: '',
        uploadFile: null,
        description: '',
        url: '',
        available_to_examiner: 0,
        available_to_account_holder: 1,
      },
      attachment_types:[
        {
          name: 'File',
          value: 'file'
        },
        {
          name: 'URL',
          value: 'url'
        }
      ],
      types:[
        {
          name: 'Enrolment',
          value: 'enrolment'
        },
        {
          name: 'Other',
          value: 'other'
        }
      ],
      currentUser: {},
      editorConfig: {
        scayt_autoStartup: true,
        scayt_sLang: 'en_GB',
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    closeDialog() {
      this.dialog = false;
      this.edit = false;
      this.errors = [];
      this.resetForm();

    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    resetData(){
      this.candidate_attachment= {
        title: '',
        candidate_id: '',
        uploaded_by_type: 'admin',
        uploaded_by: '',
        type: 'other',
        attachment_type: '',
        uploadFile: null,
        description: '',
        url: '',
        available_to_examiner: 0,
        available_to_account_holder: 1,
      };
    },
    addAttachment(item) {
      this.edit = false;
      this.resetData();
      this.openDialog();
      this.getCurrentUser();
      this.candidate_attachment.uploaded_by = this.currentUser.full_name;
      this.candidate_attachment.candidate_id = item.id;
    },
    editAttachment(item) {
      this.openDialog();
      this.edit = true;
      this.candidate_attachment = item;
      this.candidate_attachment.available_to_account_holder = item.available_to_account_holder.toString();
      this.candidate_attachment.available_to_examiner = item.available_to_examiner.toString();
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.candidate_attachment) {
        if (key === "uploadFile" && this.candidate_attachment[key] != null && this.candidate_attachment[key] != undefined) {
          formData.append('uploadFile', this.candidate_attachment[key]);
        }else {
          if(this.candidate_attachment[key]){
            formData.append(key,this.candidate_attachment[key]);
          }
        }
      }
      return formData;
    },
    uploadFile() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        let form = this.convertToFormData();
        if (this.edit) {

          attachment.update(this.candidate_attachment.id, form).then(response => {
            this.$snotify.success('Candidate attachment uploaded');
            this.closeDialog();
            this.$emit('refresh');
          })
              .catch(err => {})
              .finally(() => {
                this.loading = false;
              });
        } else {
          attachment.create(form).then(response => {
            this.$snotify.success('Candidate attachment uploaded');
            this.closeDialog();
            this.$emit('refresh');
          })
              .catch(err => {})
              .finally(() => {
                this.loading = false;
              });
        }
      }
    },
    resetForm() {
      this.candidate_attachment = {
        title: '',
        candidate_id: '',
        type: '',
        attachment_type: '',
        uploaded_by_type: 'admin',
        uploaded_by: '',
        uploadFile: null,
        description: '',
        url: '',
        available_to_examiner: "0",
        available_to_account_holder: "1",
      };
      this.errors=[];
      this.$v.$reset();
      this.$emit('refresh');
    },
  }
}
</script>