<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="600"
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>Mark as not granted</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <label for="">Feedback <span class="text-danger">*</span> </label>
                            <ckeditor
                                :config="editorConfig"
                                v-model="enrolmentInfo.award_verification_remarks"
                                :error="$v.enrolmentInfo.award_verification_remarks.$error"
                            >
                            </ckeditor>
                            <span class="text-danger" v-if="$v.enrolmentInfo.award_verification_remarks.$error">This information is required</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    class="cancel-btn"
                    x-large
                    @click="dialog = false">
                Cancel
                </v-btn>
                <v-btn
                    color="btn btn-primary"
                    dark
                    x-large
                    :loading="loading"
                    @click="declineAward()"
                >
                Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
    import {required} from "vuelidate/lib/validators";

    const enrolmentInfo = new CandidateEnrolmentInfoService();
    
    export default{
        validations: {
            enrolmentInfo:{
                award_verification_remarks: {required}
            }
        },
        data(){
            return {
                dialog: false,
                loading: false,
                enrolmentInfo:{
                    award_verification_remarks: ''
                },
                enrolmentInfoId: null,
                editorConfig: {
                    toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
                    versionCheck: false
                },
            }
        },
        methods:{
            openDialog(enrolmentInfoId){
                this.enrolmentInfo = {
                    award_verification_remarks: ''
                };
                this.enrolmentInfoId = null;
                this.enrolmentInfoId = enrolmentInfoId;
                this.dialog = true;
            },
            closeDialog(){
                this.dialog = false;
            },
            declineAward(){
                this.$confirm({
                    message: `Are you sure to mark as not granted? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                    if (confirm) {
                        this.loading = true;
                        enrolmentInfo
                        .markAwardAsRejected(this.enrolmentInfoId, this.enrolmentInfo)
                        .then((res) => {
                            this.$snotify.success("Marked as not granted");
                            this.loading = false;
                            this.closeDialog();
                            this.$emit('refresh');
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.$snotify.success("Something went wrong");
                        })
                    }
                    },
                });
            }
        }
    }
</script>