<template>
  <v-dialog v-model="dialog"
              max-width="800px"  transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit requests</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col md="12">

             <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete   @change="changeSchedulingRequest(true)" outlined dense placeholder="Select one"  :items="scheduling_requests" v-model="exam_request.schedule_request_id"
                                    item-value="id" item-text="name" label="Scheduling request">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea outlined dense placeholder="Scheduling request" v-model="exam_request.scheduling_remarks" :class="{ 'input&#45;&#45;error-enrolment': $v.exam_request.scheduling_remarks.$error}" :error="$v.exam_request.scheduling_remarks.$error"
                              label="Enter your request" v-if="schedulingRequestDetail && schedulingRequestDetail.require_additional_info">
                  </v-textarea>
                  <span class="text-danger" v-if="$v.exam_request.scheduling_remarks.$error">This information is required</span>

                </v-col>
                <v-col cols="12" md="12">
                  <label><strong>Special assistance</strong></label>
                  <v-switch @click="changeSpecialAssistance" v-model="exam_request.special_assistance_required"  color="primary"
                            :label="exam_request.special_assistance_required?' Enabled ':'Disabled'">
                  </v-switch>
                </v-col>
                <v-col cols="12" md="12" v-if="exam_request.special_assistance_required">
                  <v-textarea outlined dense  label="Enter special assistance required (Enroller)" v-model="exam_request.special_assistance_remarks_by_user">
                  </v-textarea>
                  <span class="text-danger" v-if="$v.exam_request.special_assistance_remarks_by_user.$error">This information is required</span>

                </v-col>
               <v-col cols="12" md="12" v-if="exam_request.special_assistance_required">
                  <v-textarea outlined dense  label="Enter special assistance required (Admin)" v-model="exam_request.special_assistance_remarks_by_admin">
                  </v-textarea>
                 <span class="text-danger" v-if="$v.exam_request.special_assistance_remarks_by_admin.$error">This information is required</span>
                </v-col>
               <v-col cols="12" md="3" v-if="exam_request.special_assistance_required">
                 <label>Display on</label>
               </v-col>
               <v-col cols="12" md="4" v-if="exam_request.special_assistance_required">
                 <v-checkbox v-model="exam_request.show_special_assistance_to_examiner">
                   <template v-slot:label>
                     <div>
                       <span class="font-weight-medium  subtitle-1">Examiner timetable</span>
                     </div>
                   </template>
                 </v-checkbox>
               </v-col>
               <v-col cols="12" md="4" v-if="exam_request.special_assistance_required">
                 <v-checkbox v-model="exam_request.show_special_assistance_to_supervisor">
                   <template v-slot:label>
                     <div>
                       <span class="font-weight-medium subtitle-1">Supervisor timetable</span>
                     </div>
                   </template>
                 </v-checkbox>
               </v-col>
               <v-col cols="12" md="12" v-if="exam_request.special_assistance_required">
                 <v-select @change="changeSpecialAssistanceRemark" outlined dense :items="display_remarks_types" item-text="text" item-value="value" placeholder="Display remarks of" label="Display remarks of" v-model="exam_request.timetable_special_assistance_option"  v-if="exam_request.special_assistance_required" :menu-props="{ bottom: true, offsetY: true }">
                 </v-select>
               </v-col>
              <v-col cols="12" md="12">
                 <p class="font-weight-medium">Please note any examiners for whom there may be a potential conflict of interest.
                   <a href="#">
                     <i class="fa fa-arrow-down"></i>
                   </a>
                 </p>
              </v-col>
               <v-col cols="12" md="12" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">
                 <v-combobox  @change="checkExaminer"
                              v-model="exam_request.unsuitable_examiners"
                              hide-selected outlined dense deletable-chips
                              placeholder="Enter Examiner Name" label="Enter Examiner Name"
                              multiple  small-chips>
                   <template v-slot:no-data>
                     <v-list-item>
                       <v-list-item-content>
                         <v-list-item-title>
                           Press <kbd>enter</kbd> to create a new Examiners
                         </v-list-item-title>
                       </v-list-item-content>
                     </v-list-item>
                   </template>
                 </v-combobox>
               </v-col>
              <v-col cols="12" md="12" v-if="scoreDetail  && scoreDetail.enable_unsuitable_examiners">
                 <v-textarea  outlined dense v-model="exam_request.unsuitable_examiner_comments"  placeholder="Please list any known examiners who are past teachers, family members etc, and the context" label="Reason">
                 </v-textarea>
               </v-col>
              </v-row>
             </v-col>
          </v-row>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="cancel-btn"
                x-large
                medium @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
               color="btn btn-primary" dark x-large
            @click="createOrUpdate()" :loading="loading">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
import CandidateService from "@/services/candidate/CandidateService";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";

const enrolmentYear=new EnrolmentYearService();
const schedulingRequest=new SchedulingRequestService();
const candidate=new CandidateService();
const candidateEnrolmentInfo=new CandidateEnrolmentInfoService();
const score=new ScoreService();
const accountHolder=new AccountHolderService();
export default {
  data(){
    return {
      dialog:false,
      edit:false,
      scoreId:'',
      locations:[],
      exam_request:{
        schedule_request_id:'',
        scheduling_remarks:null,
        special_assistance_required:0,
        special_assistance_remarks_by_user:'',
        special_assistance_remarks_by_admin:'',
        show_special_assistance_to_examiner:'',
        show_special_assistance_to_supervisor:'',
        timetable_special_assistance_option:'enroller',
        unsuitable_examiners:[],
        unsuitable_examiner_comments:'',
      },
      scheduling_requests:[],
      loading:false,
      candidate_enrolment_info:{},
      accountHolderDetail:{},
      scoreDetail:{},
      schedulingRequestDetail: {},
      product_overlay:false,
      candidate_overlay:false,
      old_product_price:'',
      product_price:'',
      exam_sessions:[],
      candidateId:'',
      view_closed_exam:false,
      candidate:{},
      enrolment_years:[],
      display_remarks_types:[
        {value:'admin',text:'Admin'},
        {value:'enroller',text:'Enroller'},
      ]
    }
  },
  validations(){
    return this.rules;
  },
  methods:{
    changeSpecialAssistanceRemark() {
      if(this.exam_request.timetable_special_assistance_option == 'admin') {
        // this.exam_request.special_assistance_remarks_by_user = null;
      } else {
        // this.exam_request.special_assistance_remarks_by_admin = null;
      }
    },
    getAllEnrolmentYears() {
      enrolmentYear
          .all()
          .then(response =>{
            this.enrolment_years=response.data;
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    openDialog(candidate){

      if(candidate.owner_id){
        this.candidate=candidate;
        this.$v.$reset();
        this.candidateId=candidate.id;
        this.exam_request.schedule_request_id = this.candidate.sschedule_request? this.candidate.schedule_request.id:'';
        this.exam_request.scheduling_remarks = this.candidate.scheduling_remarks? this.candidate.scheduling_remarks:'';
        this.getAccountHolderDetail(candidate.owner_id);
        this.findCandidateEnrolmentInfo(this.candidateId);
        this.dialog=true;
        this.getAllEnrolmentYears();

      }else{
        this.$snotify.error("Sorry this doesn't belong to enroller");
      }

    },
    closeDialog(){this.getAllSchedulingRequest();
      this.dialog=false;
      this.emptyFields('all');
    },
    findCandidateEnrolmentInfo(candidateId){
      candidateEnrolmentInfo
          .findByCandidateId({'candidate_id':candidateId})
          .then((response)=>{
            this.candidate_enrolment_info=response.data.enrolment_info;
            if(this.candidate_enrolment_info.unsuitable_examiners){
              this.exam_request.unsuitable_examiners=this.candidate_enrolment_info.unsuitable_examiners.split(',');
            }
            if(this.candidate_enrolment_info.unsuitable_examiner_comments){
              this.exam_request.unsuitable_examiner_comments=this.candidate_enrolment_info.unsuitable_examiner_comments;
            }
            if(this.candidate_enrolment_info.special_assistance_required){
              this.exam_request.special_assistance_required=this.candidate_enrolment_info.special_assistance_required;
            }
            if(this.candidate_enrolment_info.special_assistance_remarks_by_user){
              this.exam_request.special_assistance_remarks_by_user=this.candidate_enrolment_info.special_assistance_remarks_by_user;
            }
            if(this.candidate_enrolment_info.special_assistance_remarks_by_admin){
              this.exam_request.special_assistance_remarks_by_admin=this.candidate_enrolment_info.special_assistance_remarks_by_admin;
            }
            if(this.candidate_enrolment_info.show_special_assistance_to_supervisor){
              this.exam_request.show_special_assistance_to_supervisor=parseInt(this.candidate_enrolment_info.show_special_assistance_to_supervisor);
            }
            if(this.candidate_enrolment_info.show_special_assistance_to_examiner){
              this.exam_request.show_special_assistance_to_examiner=parseInt(this.candidate_enrolment_info.show_special_assistance_to_examiner);
            }
            if(this.candidate_enrolment_info.timetable_special_assistance_option){
              this.exam_request.timetable_special_assistance_option=this.candidate_enrolment_info.timetable_special_assistance_option;
            }

            if(this.candidate_enrolment_info.schedule_request_id){
              this.exam_request.schedule_request_id=this.candidate_enrolment_info.schedule_request_id;
              this.changeSchedulingRequest(false);
            }
            if(this.candidate_enrolment_info.scheduling_remarks){
              this.exam_request.scheduling_remarks=this.candidate_enrolment_info.scheduling_remarks;
            }
            if(this.candidate_enrolment_info.can_consider_electric_drum_kit){
              this.exam.can_consider_electric_drum_kit=this.candidate_enrolment_info.can_consider_electric_drum_kit.toString();
            }
          })
          .catch(err => {

          }).finally(() => {

        });
    },
    //AccountHolder
    getAccountHolderDetail(accountHolderId){
      accountHolder
          .show(accountHolderId)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.getAllSchedulingRequest();
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {
        this.categoryLoading = false;
      });
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
              this.getAllLocation();
              /*this.validationColourForTeacherInformation();*/
            })
            .catch(err => {

            }).finally(() => {

        });
      }
    },
    scrollValidation(){
      this.$nextTick(() => {
        if(document.querySelector('.input--error-enrolment')){
         document.querySelector('.input--error-enrolment').scrollIntoView();

        }
      })
    },
    createOrUpdate(){
      this.$v.$touch()
      if(this.$v.$error){
        this.scrollValidation()
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
        return false;
      }else {
        if(this.exam_request.unsuitable_examiners && this.exam_request.unsuitable_examiners.length>0){
          this.exam_request.unsuitable_examiners=this.exam_request.unsuitable_examiners.join(',');
        }else{
          this.exam_request.unsuitable_examiners='';
        }
        let data = {
              'exam': this.exam,
              'exam_session': this.session,
              'exam_request':this.exam_request
        };
        candidate
            .updateExamEnroled(this.candidateId, data)
            .then((response) => {
              this.$emit('refresh');
              if(response.data.status=='SUCCESS'){
                this.$snotify.success(response.data.message);
              }
              if(response.data.status=='ERROR'){
                this.$snotify.error(response.data.message);
              }
              this.closeDialog();
            })
            .catch(err => {

            });
      }

      },
    //SchedulingRequest
    getAllSchedulingRequest(){
      schedulingRequest
          .getAllSchedulingRequest({'score_id':this.scoreId})
          .then((response) => {
            this.scheduling_requests=response.data.scheduling_requests;

          })
          .catch(err => {
          });
    },
    changeSchedulingRequest(emptyField){
      this.findSchedulingRequestDetail();
      /*if(emptyField){
        this.exam_request.scheduling_remarks=null;
      }*/
    },
    changeSpecialAssistance(){
      if(this.exam_request.special_assistance_required) {
        this.exam_request.timetable_special_assistance_option = 'enroller';
      }else  {
        this.exam_request.special_assistance_remarks_by_user = null;
        this.exam_request.special_assistance_remarks_by_admin = null;
        this.exam_request.timetable_special_assistance_option = null;
        this.exam_request.show_special_assistance_to_examiner = false;
        this.exam_request.show_special_assistance_to_supervisor = false;
      }

    },
    findSchedulingRequestDetail(){
      if(this.exam_request.schedule_request_id){
        schedulingRequest
            .show(this.exam_request.schedule_request_id)
            .then((response) => {
              this.schedulingRequestDetail=response.data.schedulingRequest;

            })
            .catch(err => {

            });
      }
    },
    //Examiner
    checkExaminer(){
      if(this.exam_request.unsuitable_examiners.length>3){
        this.$snotify.error("You cannot add more than 3 Examiners");
        const index = this.exam_request.unsuitable_examiners.pop();
        if (index > -1) {
          this.exam_request.unsuitable_examiners.splice(index, 1);
        }

      }
    },
  },
  mounted() {
    //this.getAllSchedulingRequest();
  },
  computed: {
    rules() {
      return {
        exam_request:{
          special_assistance_remarks_by_user: {
            required: requiredIf(function () {
              return this.exam_request.special_assistance_required && this.exam_request.timetable_special_assistance_option == 'enroller';
            })
          },
          special_assistance_remarks_by_admin: {
            required: requiredIf(function () {
              return this.exam_request.special_assistance_required && this.exam_request.timetable_special_assistance_option == 'admin';
            })
          },
          scheduling_remarks: {
            required: requiredIf(function () {
              return this.schedulingRequestDetail && this.schedulingRequestDetail.require_additional_info;
            })
          },
        },
      }
    }
  },
}
</script>