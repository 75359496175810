<template>
    <v-dialog v-model="dialog" scrollable max-width="800">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Assign supervisor</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <div class="row">
                        <v-col cols="12" md="9">
                        <v-text-field
                            label="Search by name,email or phone"
                            v-model="search.info"
                            outlined
                            dense
                            clearable
                            v-on:keyup.enter="searchMySupervisors()"
                            @input="search.info = $event !== null ? $event : ''"
                        ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                        <v-btn
                            @click="searchMySupervisors()"
                            color="btn btn-block btn-primary"
                            dark
                            rounded
                            :loading="loading"
                            large
                        >
                            <i class="fas fa-search"></i>&nbsp; Filter
                        </v-btn>
                        </v-col>
                    </div>
                    <v-row>
                        <v-col cols="12" md="12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Full name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-if="mySupervisors.length > 0"
                                v-for="(item, index) in mySupervisors"
                                :key="index"
                            >
                                <td>
                                <strong class="w-full block">{{
                                    item.full_name ? item.full_name : "NA"
                                }}</strong>
                                </td>
                                <td>
                                {{ item.email }}
                                </td>
                                <td>
                                {{ item.phone }}
                                </td>
                                <td>
                                <v-btn
                                    color="btn btn-primary"
                                    dark
                                    @click="assignSupervisorToAward(item.id)"
                                    rounded
                                    small
                                >
                                    <i class="fas fa-user-plus"></i>&nbsp;
                                    Assign this supervisor
                                </v-btn>
                                </td>
                            </tr>
                            <tr v-if="mySupervisors.length == 0">
                                <td class="text-center" colspan="3">No supervisors found. </td>
                            </tr>
                            </tbody>
                        </table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import AmebAwardSupervisorService from "@/services/candidate/ameb-award/ameb-award-supervisor/AmebAwardSupervisorService";
import AwardContributionService from "@/services/candidate/ameb-award/award-contribution/AwardContributionService";

const amebAwardSupervisor = new AmebAwardSupervisorService();
const awardContribution = new AwardContributionService();

export default {
    data() {
        return {
            loading: false,
            dialog: false,
            candidateId: null,
            awardContributionId: null,
            mySupervisors: [],
            search: {
                info: "",
                status: 1,
                candidate_id: null
            },
            total: null,
            perPage: null,
            current_page: null,
            page: null,
        };
    },
    methods: {
        assignSupervisor(candidateId, awardContributionId) {
            this.candidateId = candidateId;
            this.search.candidate_id = candidateId;
            this.awardContributionId = awardContributionId;
            this.getMySupervisors();
            this.openDialog();
        },
        getMySupervisors() {
            this.loading = true;

            amebAwardSupervisor
                .paginate(this.search, this.page)
                .then((response) => {
                    this.mySupervisors = response.data.data;
                    this.meta = response.data.meta;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.current_page = response.data.meta.current_page;
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        searchMySupervisors() {
            this.page = 1;
            this.getMySupervisors();
        },
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        assignSupervisorToAward(supervisorId) {
            let data = {
                assigned_supervisor: supervisorId,
            };
            this.$confirm({
                message: `Are you sure you want to assign this supervisor? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        awardContribution
                            .assignSupervisor(data, this.awardContributionId)
                            .then((response) => {
                                this.$snotify.success(`Supervisor assigned successfully`);
                                this.closeDialog();
                                this.$emit('refresh');
                            })
                            .catch((err) => {
                                this.$snotify.error(`Something went wrong`);
                                this.searchMySupervisors();
                            })
                            .finally(() => {
                            })
                    }
                }
            });
        }
    },
};
</script>