<template>
    <v-dialog v-model="dialog" scrollable max-width="800">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>{{ title }} detail</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                v-model="awardPerformance.title"
                                :error="$v.awardPerformance.title.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Performance Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.awardPerformance.title.$error">This information is required</span>
                            <span class="text-danger" v-if="errors.title" >* {{ errors.first_name[0] }}</span>
                        </v-col>
                        <v-col cols="12" md="12">
                            <div>Description <span class="text-danger">*</span></div>
                            <!-- <div> 
                                <a @click="showDescriptionText">    
                                    <svg class="ml-4 mb-2" width="20" height="22" viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <circle fill="#D8D8D8" cx="10" cy="12" r="10"/>
                                            <text font-family="Merriweather-BlackItalic, Merriweather" font-size="12" font-style="italic" font-weight="800" fill="#767676">
                                                <tspan x="8.038" y="17">i</tspan>
                                            </text>
                                        </g>
                                    </svg>
                                </a>
                            </div> -->
                            <div class="mb-2" style="color: #8d8d8d;">
                                Tell us about your performance: who is performing, what sort of repertoire, who is organising it (eg school), 
                                the expected audience number, duration, your contribution etc.
                            </div>
                            
                            <ckeditor
                                :config="getEditorConfig()"
                                v-model="awardPerformance.description"
                            >
                            </ckeditor>
                            <span class="text-danger" v-if="errors.description">
                                {{errors.description[0]}}
                            </span>
                            <span class="text-danger" v-if="$v.awardPerformance.description.$error"
                            >This information is required</span>
                        </v-col>

                        <v-col>
                            Nominated discipline
                            <v-switch
                                v-model="awardPerformance.nominated_instrument"
                                :label="awardPerformance.nominated_instrument?'Yes':'No'"
                            ></v-switch>
                        </v-col>

                        <v-col cols="12">
                            Dates <span class="text-danger">*</span>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        label="Select date or range of dates"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        v-on:click:prepend-inner="menu=true"
                                        outlined
                                        dense
                                        :error="$v.awardPerformance.dates.$error"

                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dates"
                                    no-title
                                    @input="menu = false"
                                    range
                                ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.awardPerformance.dates.$error"
                            >This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-3">
                            Venue name and address <span class="text-danger">*</span>
                            <ckeditor
                                :config="getEditorConfig()"
                                v-model="awardPerformance.venue_details"
                            >
                            </ckeditor>
                            <span class="text-danger" v-if="$v.awardPerformance.venue_details.$error">This information is required</span>
                            <span class="text-danger" v-if="errors.venue_details" >* {{ errors.venue_details[0] }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn x-large text @click="closeDialog">
                    Cancel
                </v-btn>
                <v-btn dark x-large @click="createOrUpdate()" :loading="loading">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import { required, requiredIf, email } from "vuelidate/lib/validators";
import AwardPerformanceService from "@/services/candidate/ameb-award/award-performance/AwardPerformanceService";

const awardPerformance = new AwardPerformanceService;

export default {
    validations:{
        awardPerformance:{
            title: {required},
            description: {required},
            nominated_instrument: {},
            dates: {required},
            venue_details: {required}
        }
    },
    data(){
        return{
            loading: false,
            dialog: false,
            title: '',
            menu: false,
            awardPerformance:{
                title: '',
                description: '',
                nominated_instrument: 0,
                venue_details: '',
            },
            dates: [],
            errors: [],
            awardPerformanceId: null,
            showDescription: false,
        }
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods:{
        showDescriptionText(){
            this.showDescription = !this.showDescription;
        },
        addDetail(awardPerformance){
            this.awardPerformance = awardPerformance;
            this.dates = [];

            this.title = 'Add';
            this.awardPerformanceId = awardPerformance.id;
            this.edit = false;
            this.openDialog();
        },
        editDetail(awardPerformance){
            this.awardPerformance = awardPerformance;
            this.dates = [];
            if(this.awardPerformance.dates){
                // this.dates = this.awardPerformance.dates_formatted;
                this.dates.push(this.awardPerformance.dates);
            }
            this.title = 'Edit';
            this.awardPerformanceId = awardPerformance.id;
            this.edit = false;
            this.openDialog();
        },
        openDialog(){
            this.dialog = true;
        },
        closeDialog(){
            this.dialog = false;
        },
        createOrUpdate(){
            this.awardPerformance.dates = this.dates.join(',');
            this.$v.$touch();
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 60000);
            } else {
                this.loading = true;
                this.updateAwardPerformance();
            }
        },
        updateAwardPerformance(){
            awardPerformance
            .update(this.awardPerformanceId, this.awardPerformance)
            .then((response) => {
                this.$snotify.success(`Details updated`);
                this.$emit('refresh');
                this.loading = false;
                this.closeDialog();
            })
            .catch((err) => {
                this.errors = err.errors;
                this.loading = false;
            })
            .finally(() => {

            });
        },
        resetForm(){
            this.awardPerformance={
                title: '',
                description: '',
                nominated_instrument: 0,
                dates: '',
                venue_details: ''
            };
            this.errors = [];
            this.$v.$reset();
        },
        getEditorConfig(){
            return {
              toolbar: [
                  ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'SpellChecker', 'Scayt','RemoveFormat']
              ],
              scayt_sLang: 'en_GB',
              scayt_autoStartup: true,
              allowedContent: true,
              scayt_disableOptionsStorage: 'lang',
              versionCheck: false
            }
          }
    }
}
</script>