<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Change enrolment pin</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="border">
              <label>Current enrolment pin</label>
              <v-text-field 
                v-if="candidate.exam_pin"
                label="Current enrolment pin"
                v-model="candidate.exam_pin"
                outlined
                disabled
                dense
              ></v-text-field>
              <div v-else>
                Not added
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="updateExamPin()"
            :loading="loading"
        >
          Change Pin
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, alphaNum, minLength, maxLength} from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";

const candidate = new CandidateService();

export default {
  name: "EditExamPin",
  data() {
    return {
      errors: [],
      dialog: false,
      loading: false,
      candidate: {
        exam_pin: '',
      }
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.dialog = true;
    },
    editExamPin(item) {
      this.openDialog();
      this.candidate = item;
    },
    updateExamPin() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            candidate
            .updateExamPin(this.candidate.id)
            .then(response => {
              this.$snotify.success("Enrolment pin changed successfully, please check your email.");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
          }
        }
      })
    },
    resetForm() {
      this.errors=[];
      this.candidate = {
        exam_pin:'',
      };
    },
  }
}
</script>

<style scoped>

</style>