import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateRecognitionService
{
    #api = null;    
    constructor() {
        this.#api = API_URL + 'admin/candidate-recognition';
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }
}
