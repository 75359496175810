import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class CandidateShareRequestService
    {
        #api = null;    
        constructor() {
            this.#api = API_URL + 'admin/candidate-share-request';
        }
        paginate(data={},index = null) {
            let url = `${this.#api}`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        getByCandidate(candidateId) {
            let url = `${this.#api}/${candidateId}/get-by-candidate`;
            return apiService.get(url);
        }

        create(data) {
            let url = `${this.#api}`;
            return apiService.post(url, data);
        }

        revoke(candidateShareRequestId) {
            let url = `${this.#api}/${candidateShareRequestId}`;
            return apiService.delete(url);
        }

    }
