<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit local enrolment data</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2"> 
            <v-col cols="12" md="12">
                <v-text-field
                    v-model="candidate_recognition.tasc_id_tas"
                    outlined
                    dense
                >
                    <template v-slot:label>
                    TASC ID 
                    </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.candidate_recognition.tasc_id_tas.$error">This information is required</span>
                <span class="text-danger" v-if="errors.tasc_id_tas" >{{errors.tasc_id_tas[0]}}</span>

            </v-col>

            <v-col cols="12" md="12">
                <v-select
                    v-model="candidate_recognition.state_id"
                    outlined
                    :items="states"
                    item-text="name"
                    item-value="id"
                    dense
                >
                    <template v-slot:label>
                    State
                    </template>
                </v-select>
                <span class="text-danger" v-if="$v.candidate_recognition.state_id.$error">This information is required</span>
                <span class="text-danger" v-if="errors.state_id" >{{errors.state_id[0]}}</span>

            </v-col>

            <v-col cols="12" md="12">
                <v-text-field
                    v-model="candidate_recognition.address_line_1"
                    outlined
                    dense
                >
                    <template v-slot:label>
                    Address line 1
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
                <v-text-field
                    v-model="candidate_recognition.address_line_2"
                    outlined
                    dense
                >
                    <template v-slot:label>
                    Address line 2
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
                <v-text-field
                    v-model="candidate_recognition.suburb"
                    outlined
                    dense
                >
                    <template v-slot:label>
                    Suburb
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
                <v-text-field
                    v-model="candidate_recognition.post_code"
                    outlined
                    dense
                >
                    <template v-slot:label>
                    Post code
                    </template>
                </v-text-field>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      
      <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
                text
                x-large
                @click="closeDialog">
            Cancel
            </v-btn>
            <v-btn
                dark
                x-large
                @click="updateTascFields()"
                :loading="loading"
            >
            Save
            </v-btn>
      </v-card-actions>
    
    </v-card>
  </v-dialog>
</template>


<script>
import CandidateRecognitionService from "@/services/candidate/candidate-recognition/CandidateRecognitionService";
import StateService from "@/services/setting/state/StateService";
import {required} from "vuelidate/lib/validators";

const candidateRecognition = new CandidateRecognitionService(); 
const state = new StateService(); 

export default {
    validations: {
        candidate_recognition:{
            tasc_id_tas: {},
            state_id: {}
        }
    },
    data(){
        return{
            dialog: false,
            candidate_recognition: '',
            loading: false,
            errors: []
        }
    },
    mounted(){
        this.getAllStates()
    },
    methods:{
        closeDialog(){
            this.dialog = false;
            this.$emit('refresh');
        },
        editTascFields(candidateRecognition){
            this.candidate_recognition = candidateRecognition;
            this.dialog = true;
        },
        updateTascFields(){
            this.$v.$touch();

            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;

                candidateRecognition
                .update(this.candidate_recognition.id, this.candidate_recognition)
                .then(response => {
                    this.$snotify.success("Local enrolment data updated");
                    this.closeDialog();
                    this.loading = false;
                    this.$emit('refresh');
                })
                .catch((err) => {
                    this.loading = false;
                    this.errors = err.errors;
                    this.$snotify.error("Oops something went wrong");
                });
            }
        },
        getAllStates(){
            state
            .all()
            .then((res) => {
                this.states = res.data.data;
            })
            .catch((err) => {

            });
        }
    }
}
</script>
