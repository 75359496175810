import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AwardContributionService{
    #api = null;
    
    constructor() {
        this.#api = API_URL + 'admin/award-contribution';
    }

    getByCandidate(candidateId,index = null, data = {}) {
        let url = `${this.#api}/${candidateId}/get-by-candidate`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    viewDetail(contributionId) {
        let url = `${this.#api}/view-performance/${contributionId}`;
        return apiService.query(url);
    }

    update(id,data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url,data)
    }

    changeStatus(id,data) {
        let url = `${this.#api}/${id}/change-status`;
        return apiService.put(url,data)
    }

    assignSupervisor(data, awardContribution) {
        let url = `${this.#api}/${awardContribution}/assign-supervisor`;
        return apiService.put(url,data)
    }

    resendNotificatioMail(awardPerformanceId){
        let url = `${this.#api}/${awardPerformanceId}/resend-notification`;
        return apiService.post(url)
    }

    notifySupervisorEmail(awardPerformanceId){
        let url = `${this.#api}/${awardPerformanceId}/notify-supervisor`;
        return apiService.put(url)
    }
}
