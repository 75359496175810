<template>
    <v-dialog 
        v-model="dialog"
        max-width="800px"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>View performance details</span>
                <hr />
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                          type="table-thead"
                          v-if="loading"
                      >
                      </v-skeleton-loader>
                      <v-skeleton-loader
                          v-if="loading"
                          type="table-row-divider@5"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                    <v-row v-if="!loading">
                      <v-col cols="4">
                        <b>Title</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{performance.title ? performance.title : 'NA'}}</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Description</b>
                      </v-col>
                      <v-col cols="12" v-if="performance.description">
                        <span v-html="performance.description">{{performance.title}}</span>
                      </v-col>
                      <v-col v-else cols="8">
                        <span>NA</span>
                      </v-col>
                      
                      <v-col cols="4">
                        <b>Nominated discipline</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-html="performance.nominated_instrument_badge"></div>
                      </v-col>

                      <v-col cols="4" v-if="performance.dates_formatted">
                        <b>Dates</b>
                      </v-col>
                      <v-col cols="8" v-if="performance.dates_formatted">
                        <span>{{performance.dates_formatted[0]}} - {{ performance.dates_formatted[1] }}</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Venue details</b>
                      </v-col>
                      <v-col cols="8">
                        <span v-if="performance.venue_details" v-html="performance.venue_details"></span>
                        <span v-else >NA</span>
                      </v-col>

                      <v-col cols="4">
                        <b>Assigned supervisor</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{performance.assigned_supervisor_full_name ? performance.assigned_supervisor_full_name : 'NA'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Assigned supervisor email</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{performance.assigned_supervisor_email ? performance.assigned_supervisor_email : 'NA'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Supervisor notified</b>
                      </v-col>
                      <v-col cols="8">
                        <span>{{performance.is_supervisor_notified ? 'Yes' : 'No'}}</span>
                      </v-col>
                      <v-col cols="4">
                        <b>Status</b>
                      </v-col>
                      <v-col cols="8">
                        <div v-html="performance.status_badge"></div>
                      </v-col>
                      <v-col cols="4" v-if="performance.status=='declined'">
                        <b>Supervisor declined date</b>
                      </v-col>
                      <v-col cols="8" v-if="performance.status=='declined'">
                        <span>{{performance.formatted_approved_declined_date}}</span>
                      </v-col>
                      <v-col cols="4" v-if="performance.status=='declined'">
                        <b>Supervisor declined remarks</b>
                      </v-col>
                      <v-col cols="8" v-if="performance.status=='declined'">
                        <span>{{performance.declined_remarks}}</span>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    x-large
                    @click="closeDialog">
                Cancel
                </v-btn>


            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AwardPerformanceService from "@/services/candidate/ameb-award/award-performance/AwardPerformanceService";
    const performanceService = new AwardPerformanceService();

    export default{
        data(){
            return{
                performanceId: null,
                performance: null,
                dialog: false,
                loading: true
            }
        },
        methods:{
            openDialog(performanceId){
              this.dialog = true;
              this.loading = true;
              performanceService
                  .viewDetail(performanceId)
                  .then((response) => {
                    this.performance = response.data.data
                  })
                  .catch((err) => {
                    this.$snotify.error("Oops something went wrong");
                  })
                  .finally(() => {
                    this.loading = false;
                  })
            },
            closeDialog(){
                this.dialog = false;
            },

        }
    }
</script>