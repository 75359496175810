<template>
    <v-app>
        <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span >{{log.title}} Details</span>
          </v-card-title>
          <v-card-subtitle class="ml-1" v-if="log.admin_user">
            <span class="font-size-md">
              By
                <span v-if="log.admin_user && log.admin_user.length > 0">{{log.admin_user[0].first_name}} {{log.admin_user[0].last_name}}</span>
               on {{log.formatted_log_date}}
            </span>
          <v-divider></v-divider>
          </v-card-subtitle>
          <v-card-text>
            <div class="contents__box contents__box--left w-100">
              <ul v-if="log.compared_data && log.compared_data.length != 0" id="changelog">
                <li class="card border-0 accordion__item">
                  <div id="metronic3812743307" class="collapse show" role="tabpanel">
                    <ul>
                      <li>
                        <ul v-for="(item, index) in log.compared_data" :key="index">
                          <li class="mb-2 pl-2 py-1">
                            <code style="font-size: 14px; font-weight: bold;" class="ms-0">
                              {{item.field == 'Owner id' ? 'Owner' : item.field}} 
                            </code> 
                            <span class="" style="font-size:14px;"> 
                              changed from
                            </span>
                            <code style="font-size: 14px; font-weight: bold;" class="ms-0">
                              {{ item.original ? item.field == 'Owner id' ? getPreviousOwnerName(item.original) : item.original : '-' }}
                            </code> 
                            <span class="" style="font-size:14px;"> 
                              to
                            </span>
                            <code style="font-size:14px; font-weight: bold;">
                              {{item.field == 'Owner id' ? getNewOwnerName(item.changed) : item.changed }}
                            </code>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn btn-danger text-white"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
</template>

<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const accountholder = new AccountholderService;

export default {
  data(){
    return{
      dialog: false,
      log : {},
      previousName: '',
      newName: ''
    }
  },
  methods:{
    showLogDetail(item){
      this.log = item;
      this.showDialog();
    },
    showDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    getNewOwnerName(id){
      accountholder
      .show(id)
      .then((response) => {
        this.newName = response.data.accountHolder.full_name;
      })
      .catch((err) => {
        this.newName = '';
      })
      return(this.newName);
    },
    getPreviousOwnerName(id){
      accountholder
      .show(id)
      .then((response) => {
        this.previousName = response.data.accountHolder.full_name;
      })
      .catch((err) => {
        this.previousName = '';
      })
      return(this.previousName);
    },
  }   
}
</script>
