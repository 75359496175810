<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit expiry date</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="candidate.enrolment_expiry_date"
                      chips
                      small-chips
                      label="Expiry date *"
                      :error="$v.candidate.enrolment_expiry_date.$error"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="candidate.enrolment_expiry_date"
                    @input="menu = false"
                    outlined
                    dense
                    no-title
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="errors.enrolment_expiry_date" >{{errors.enrolment_expiry_date[0]}}</span>
              <span class="text-danger" v-if="$v.candidate.enrolment_expiry_date.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            dark
            x-large
            @click="updateCandidate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required} from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";

const candidate = new CandidateService();

export default {
  name: "EditExpiryDate",
  data() {
    return {
      errors: [],
      dialog: false,
      menu: false,
      loading: false,
      candidate: {
        enrolment_expiry_date: '',
      }
    }
  },
  validations: {
    candidate: {
      enrolment_expiry_date: { required },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editExpiryDate(item) {
      this.openDialog();
      this.candidate = item;
    },
    updateCandidate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        candidate
            .update(this.candidate.id, this.candidate)
            .then(response => {
              this.$snotify.success("Expiry date updated");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.candidate = {
        enrolment_status:'',
      };
    },
  }
}
</script>

<style scoped>

</style>