<template>
  <v-app>
    <div class="row mt-1">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Candidate information log
                </h4>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
            </v-skeleton-loader>
            
            <div class="contents__box contents__box--left w-100">
              <ul class="pl-5" v-if="logs.length != 0" id="changelog">
                <li class="card border-0 accordion__item">
                  <a class="collapsed mb-2" data-toggle="collapse" data-parent="#changelog" href="#metronic3812743307" aria-expanded="false">
                    <div class="accordion__item_top">
                      <h5>Your Activity Logs</h5>
                    </div>
                  </a>
                  <div id="metronic3812743307" class="collapse show" role="tabpanel">
                    <ul>
                      <li>
                        <ul>
                          <li class="mb-2 py-1" v-for="(item, index) in logs" v-if="item.compared_data.length > 0" :key="index">
                            <code style="font-size: 14px;" class="ms-0">
                              {{item.title}}
                            </code> 
                            <span class="ml-2" style="font-size:14px;">
                              by
                                <span v-if="item.admin_user && item.admin_user.length > 0">{{item.admin_user[0].first_name}} {{item.admin_user[0].last_name}}</span>
                              on
                            </span>
                            <code style="font-size:14px;">{{item.formatted_log_date}}</code>
                            <a @click="showLogDetail(item)" style="font-size:14px;margin-left: 10px" class="fw-bold" target="_blank">Preview</a>.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <b-pagination
                v-if="logs.length > 0"
                class="pull-right mt-7"
                @input="getCandidateLogs"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :disabled="loading"
                last-number
              ></b-pagination>
              <div v-else class="text-center card-body">
                <NotFound 
                  :message="'Logs Not Found'" 
                ></NotFound>
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <LogDetail ref="show-log-detail"></LogDetail>

    </div>
  </v-app>
</template>

<script>
import NotFound from "@/view/pages/view/components/NotFound";
import CandidateService from "@/services/candidate/CandidateService"
import LogDetail from "./LogDetail.vue";
const candidate = new CandidateService();

export default {
  components: {NotFound, LogDetail},
  props: ['candidate_id'],
  data(){
    return{
        total: null,
        perPage: null,
        page: null,
        logs : [],
        loading: true,
    }
  },
  mounted() {
    this.getCandidateLogs();
  },
  methods:{
    getCandidateLogs(){
        this.loading = true;
        candidate
        .getLogs(this.search, this.candidate_id, this.page)
        .then(response => {
            this.page = response.data.logs.current_page;
            this.total = response.data.logs.total;
            this.perPage = response.data.logs.per_page;
            this.logs = response.data.logs.data;
            this.loading = false;
        })
        .catch((err) => {
        })
        .finally(() => {
            this.loading = false;
        });
    },
    showLogDetail(log){
      this.$refs['show-log-detail'].showLogDetail(log);
    },
  },

}
</script>